.#{$m-css-prefix}-Card {
  border: 0 none;
  margin-top: m-toRem($m-f8-8);
  margin-bottom: m-toRem($m-f8-8);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  color: $aa-color-gray-700;
  border-radius: 0;
  padding: m-toRem($m-f8-4);

  &-wrapper {
    display: flex;
  }

  @include media-breakpoint-up(sm) {
    margin-top: m-toRem($m-f8-16);
    margin-bottom: m-toRem($m-f8-16);
  }

  +.#{$m-css-prefix}-Card {
    margin-top: m-toRem($m-f8-40);
  }

  .card-title {
    margin-bottom: 0;
    padding: 1.25rem 1.25rem 0 1.25rem;
    color: $aa-color-blue;

    h2,
    h3,
    h4,
    h5 {
      margin-top: 0;
    }
  }

  .#{$m-css-prefix}-Card-date {
    font-size: m-toRem(14px);
    font-weight: 600;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    padding-top: m-toRem($m-f8-16);
    padding-bottom: m-toRem($m-f8-16);
  }

  a,
  p {
    color: inherit;
  }

  .card-title, .card-body {
    a {
      text-decoration: none;
    }
  }

  .card-title {
    a {
      text-decoration: none;
      &:hover {
        color: #000a5a;
      }
    }
  }

  .card-text {

    >*:last-child {
      margin-bottom: 0;
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    padding-top: m-toRem($m-f8-8);
    padding-bottom: m-toRem($m-f8-8);
    color: rgba($body-color, 0.65);

    >*:not(:last-child) {
      margin-right: m-toRem($m-f8-8);
    }
  }

  [class*="btn-"] {
    margin-top: m-toRem($m-f8-8);
  }

  &--news {
    box-shadow: 0px 0px 14px #E9ECF4;
    padding: 0;

    &.protected {
      .card-subtitle {
        position: relative;
        top: 4px;
      }
    }

    .#{$m-css-prefix}-Card {
      &-content {
        padding: 27px 30px 0 30px;
        margin-bottom: 33px;
      }

      &-upper {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 10px;

        svg {
          margin-right: 10px;
        }
      }

      &-lower {
        margin-top: auto;

        &-inner {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: 0 30px 0 30px;
          margin: 0 0 32px 0;
          flex-direction: column;
        }
      }

      &-date {
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: #000a5a;
        font-weight: 400;
      }
      &-image {
        height: 244px;
        width: 100%;
        object-fit: cover;
      }
    }

    .card-subtitle {
      font-size: 15px;
      line-height: 24px;
      font-weight: 600;
      color: #234796;
      text-transform: uppercase;
    }

    .card-title {
      padding: 0;
      color: #000a5a;
      margin-bottom: 10px;
    }

    .card-body {
      padding: 0;

      .card-text {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &--news-webinar {
    .#{$m-css-prefix}-Card {
      &-title {
        margin-top: 10px;
      }

      &-content {
        padding: 18px 30px 0 30px;
      }

      &-btn-bottom {
        position: relative;
      }
    }

  }

  &--news {
    .#{$m-css-prefix} {
      &-News {
        &-tags {
          margin-top: 0;
        }

        &-tag {
          margin-bottom: 0;
          padding: 0;
        }
      }
    }
  }

  .aa-btn--arrow {
    align-self: flex-start;
  }

}


.#{$m-css-prefix}-Card .#{$m-css-prefix}-Card-image-wrapper .card-img-top {
  border-radius: 0;
}

.#{$m-css-prefix}-Card-figure {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zIndex-1+1;
  transition: $transition-base;
  color: white;
  line-height: 120%;
  font-size: 2.5rem;
  font-weight: 400;
  padding: 2rem 6rem 2rem 2rem;
  margin: 0;

  svg {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    fill: $aa-color-orange !important;
    transition: $transition-base;
  }

  @include media-breakpoint-only(md) {
    font-size: 1.6rem;
  }

  @include media-breakpoint-down(xs) {
    font-size: 1.5rem;
  }
}


.#{$m-css-prefix}-Card-icon-wrapper {
  padding: m-toRem($m-f8-40) $card-spacer-x 0;
}

.#{$m-css-prefix}-Card-icon {
  display: inline-block !important; // Slick sets images to 'block'
}

.#{$m-css-prefix}-Card-image-wrapper {}

.#{$m-css-prefix}-Card-image {
  @include img-fluid();
}

.#{$m-css-prefix}-Card-date {
  display: inline-block;
}

.#{$m-css-prefix}-Card-image-link {
  transition: $transition-base;
  overflow: hidden;
  position: relative;

  img {
    transition: $transition-base-slow;
  }


  &:before {
    content: '';
    position: absolute;
    z-index: $zIndex-1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: $transition-base;
  }

  &:before {
    opacity: 1;
    background-color: $aa-color-rgba-blue;
    background: linear-gradient(transparent 20%, $aa-color-blue);
  }


  &:hover {

    .#{$m-css-prefix}-Card-figure {
      color: $aa-color-blue;

      svg {
        fill: $aa-color-blue !important;
      }
    }

    &:before {
      opacity: 0;
    }
  }
}


// Modifiers
// -----------------------------------------------------------------------------
.#{$m-css-prefix}-Card.#{$m-css-prefix}-Card--spacious {

  .card-body {

    @include media-breakpoint-up(md) {
      padding: m-toRem($m-f8-64);
    }
  }
}

.#{$m-css-prefix}-Card.#{$m-css-prefix}-Card--link {
  transition: $transition-base;

  &:hover {
    transform: scale(1.025);
  }

  .#{$m-css-prefix}-Card-link,
  .#{$m-css-prefix}-Card-link:hover {
    text-decoration: none;
  }

  .#{$m-css-prefix}-Card-link:visited {
    color: inherit;
  }
}

.#{$m-css-prefix}-Card.#{$m-css-prefix}-Card--ghost {
  background-color: transparent;
  color: $aa-color-gray-700;
  box-shadow: none;

  p {
    color: $aa-color-gray-700;
  }

  svg {
    fill: $aa-color-blue;
  }

}

// Testemonial-Card-Headlines immer Schrift Light
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Testemonials {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
}


// In context
// -----------------------------------------------------------------------------

.#{$m-css-prefix}-Section--featureGrid .#{$m-css-prefix}-Card {
  @include media-breakpoint-up(md) {
    height: calc(100% - 2rem);
  }
}

.#{$m-css-prefix}-Section--special-grid .#{$m-css-prefix}-Card {
  height: calc(100% - 1rem);

  @include media-breakpoint-up(sm) {
    height: calc(100% - 2rem);
  }
}


// Protected News Card Stylings
//----------------------------------------------------------------------------------------------------------------------

// news card add lock symbol
.#{$m-css-prefix}-Card.#{$m-css-prefix}-Card--news.protected {

  .#{$m-css-prefix}-Card-title {
    position: relative;
  }
}


// Testemonial round cards
// -----------------------------------------------------------------------------

.#{$m-css-prefix}-Card.#{$m-css-prefix}-Card--testemonial.#{$m-css-prefix}-Card--round {

  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  .#{$m-css-prefix}-Card-content {
    @include media-breakpoint-up(sm) {
      padding-left: m-toRem($m-f8-24);
      padding-right: m-toRem($m-f8-24);
    }

    @include media-breakpoint-up(md) {
      padding-left: m-toRem($m-f8-40);
    }

    @include media-breakpoint-up(xl) {
      padding-left: m-toRem($m-f8-64);
    }
  }

  .#{$m-css-prefix}-Card-body {
    padding-left: 0;
    padding-right: 0;

  }

  .#{$m-css-prefix}-Card-image-wrapper {
    position: relative;
    flex: 0 0 m-toRem($m-f8-272);
    overflow: hidden;
    width: m-toRem($m-f8-272);
    height: m-toRem($m-f8-272);
    margin: 0 auto;
    border-radius: 50%;
    border: m-toRem($m-f8-4) solid $white;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
      max-width: none;
    }
  }

}
