@use "sass:math";
.container {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

.#{$m-css-prefix}-Container {
}

$grid_gutter_half: #{math.div($grid-gutter-width,2)};

.#{$m-css-prefix}-Container--left .#{$m-css-prefix}-Row {
  padding-left: $grid_gutter_half;

  .column-1 {
    padding-right: $grid_gutter_half;

    @include media-breakpoint-up(lg) {
      padding-left: calc((50vw - #{math.div(map-get($container-max-widths, lg), 2)}) + #{$grid_gutter_half});

    }

    @include media-breakpoint-up(xl) {
      padding-left: calc((50vw - #{math.div(map-get($container-max-widths, xl), 2)}) + #{$grid_gutter_half});
    }
  }
}


.#{$m-css-prefix}-Container--right .#{$m-css-prefix}-Row {
  padding-left: $grid_gutter_half;

  .column-2 > * {

    @include media-breakpoint-up(lg) {
      padding-right: calc((50vw - #{math.div(map-get($container-max-widths, lg), 2)}) + #{$grid_gutter_half});
      padding-left: $grid_gutter_half;
    }

    @include media-breakpoint-up(xl) {
      padding-right: calc((50vw - #{math.div(map-get($container-max-widths, xl), 2)}) + #{$grid_gutter_half});
    }
  }
}

.#{$m-css-prefix}-Section .container-fluid {
  padding-right: 0;
  padding-left: 0;

  > .row{
    margin-left: 0;
    margin-right: 0;

  }
}


// Fullwidth Image mit Vertriebsleuten über Formular
//----------------------------------------------------------------------------------------------------------------------

.frame-auae-fullwidth .ce-column.col-md {
  padding: 0;
}
