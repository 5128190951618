@use 'sass:math';

#{$m-css-prefix}-Header {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // z-index: $zindex-fixed;
}

//
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--main {
  // background-color: white;
  color: $aa-color-blue;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .#{$m-css-prefix}-Header-pre {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
    margin-top: m-toRem($m-f8-16);
    margin-bottom: 0;
    list-style: none;
    font-weight: 500;

    @include media-breakpoint-down(md) {
      display: none;
    }

    ul {
      list-style-type: none;
      display: flex;
    }

    li {
      &:not(.#{$m-css-prefix}-Header-pre-right--btn) {
        padding: m-toRem($m-f8-4) m-toRem($m-f8-16);
      }

      &.#{$m-css-prefix}-SocialmediaSvg {
        padding: m-toRem($m-f8-4) m-toRem($m-f8-8);
      }
    }

    a,
    a:visited {
      text-decoration: none;
      color: inherit;
      font-size: .8rem;
    }

    a:hover {
      color: $aa-color-orange;
    }

    a.#{$m-css-prefix}-btn--blue,
    .#{$m-css-prefix}-btn--blue a {
      color: white;
    }

    &-left {
      margin-bottom: 1rem;
      white-space: nowrap;

      li:first-child {
        padding-left: 0.25rem;
      }

      .#{$m-css-prefix}-Icon {
        width: .8rem;
        height: .8rem;
      }
    }


    &-right {
      justify-content: flex-end;
      margin-bottom: 0;
      white-space: nowrap;

      li:last-child {
        padding-right: 0;
        padding-left: 1rem;
      }

      .#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--lock {
        width: .8rem;
        height: .8rem;
        padding-right: .25rem;
        margin-top: -3px;

      }

      .#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--socialmedia {
        width: 1.5rem;
        height: 1.5rem;

      }
    }
  }

  a.aa-Logo-link {
    color: inherit;

    &:visited {
      color: inherit;
    }
  }
}

.#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--white {
  color: white;

  .nav-droppdown-active &,
  .on-page-nav & {
    color: $aa-color-blue;
  }

  .aa-Header-pre-right--btn{

    a.#{$m-css-prefix}-btn--blue,
    .#{$m-css-prefix}-btn--blue a {
      color: $aa-color-blue;

      &:after {
        background-color: $white;
      }

      &:hover{
        color: #234796;
      }

      .nav-droppdown-active &{
        color: $white;

        &:after {
          background-color: $aa-color-blue;
        }
      }
    }
  }
}

// Mobile Header
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--mobile {
  position: fixed;
  background-color: white;
  box-shadow: 0 4px 2px -2px $gray-400;

  @include media-breakpoint-up(lg) {
    display: none;
    box-shadow: none;
  }

  .aa-Header--mobile-wrap{
    height: $aa-header-height-mobile;
    padding: 0.5rem m-toRem($grid-gutter-width);
  }
}


// Switch Header
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--switch {

  color: $aa-color-blue;

  @include media-breakpoint-down(sm) {
    background-color: white;
    box-shadow: 0 4px 2px -2px $gray-400;
  }

  .#{$m-css-prefix}-Logo-link {
    height: $aa-header-height-mobile;
    padding: m-toRem($m-f8-8) m-toRem($m-f8-16);

    color: inherit;

    &:visited {
      color: inherit;
    }
  }

  .#{$m-css-prefix}-Header-pre {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding: m-toRem($m-f8-4) 0 m-toRem($m-f8-16) 0;
    }

    .#{$m-css-prefix}-Header-switch-link {
      text-decoration: none;
      color: inherit;
      font-size: .8rem;
      padding-left: 1rem;
    }
  }

}

// Empty Header
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--empty {
  position: fixed;
  height: 4rem;
  left: 0;
  right: 0;
  top: 0;
  background: $aa-color-blue;
  padding: m-toRem($m-f8-4) #{math.div($grid-gutter-width,2)};
  z-index: 100;
}


// mods fo onpage header
//----------------------------------------------------------------------------------------------------------------------

.on-page-nav .#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--main {
  position: fixed;
  transform: translateY(-100%);

  .#{$m-css-prefix}-Navigation--main {
    width: 100%;
  }

  .#{$m-css-prefix}-Header-pre {
    display: none;
  }

  .navbar-nav {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .#{$m-css-prefix}-Logo-link {
    height: 64px;
    padding: .5rem 0 .5rem 1rem;
  }

  .#{$m-css-prefix}-Navigation-bar {
    justify-content: space-evenly;
  }
}

.on-page-nav.animated .#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--main {
  transition: all .2s ease-in-out;
}

.on-page-nav.active .#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--main {
  transform: translateY(0);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
}


body.nav-droppdown-closing:not(.on-page-nav) .#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--main {
  background-color: transparent;
  transition: $duration-normal all $duration-normal ease-in-out;

}

body.nav-droppdown-active:not(.on-page-nav) .#{$m-css-prefix}-Header.#{$m-css-prefix}-Header--main {
  background-color: white;
  transition: all $duration-normal ease-in-out;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
