.#{$m-css-prefix}-EventList-item {
  // padding: m-toRem($m-f8-16 * 2);
  background-color: white;
  margin-bottom: m-toRem($m-f8-24);
  box-shadow: 0px 0px 14px #E9ECF4;

  @include media-breakpoint-up(lg) {
    padding: 0 32px 0 0;
  }

  &-lower {}

  .#{$m-css-prefix}-Eventlist-body {
    // padding: m-toRem($m-f8-16 * 2);
    padding: 24px 32px 36px 41px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      margin-bottom: m-toRem($m-f8-16);
    }
  }

  .#{$m-css-prefix}-EventList-btnWrap {
    padding: 0 15px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: end;

    >a {
      margin: 0;
      align-self: end;
    }

    @include media-breakpoint-down(md) {
      >a {
        margin-right: 20px;
      }
    }
  }

  .#{$m-css-prefix}-Eventlist-img {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(xs) {
      padding: 45px;
      background-color: #F1F5FF;
    }

    @include media-breakpoint-up(lg) {
      background-color: #F1F5FF;
    }

  }

  .#{$m-css-prefix}-EventList-tags {
    p {
      color: $aa-color-blue;
    }
  }

  p.#{$m-css-prefix}-EventList-warning {
    background-color: $aa-color-red;
    color: #fff;
    font-size: .875rem;
    padding: .25rem .5rem;
    border-radius: .25rem;
    text-transform: none;
    margin: .75rem .5rem .5rem 0;
  }

  p.#{$m-css-prefix}-EventList-info {
    background-color: $aa-color-orange;
    color: #fff;
    font-size: .875rem;
    padding: .25rem .5rem;
    border-radius: .25rem;
    text-transform: none;
    margin: .75rem .5rem .5rem 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(xs) {
    // padding: m-toRem($m-f8-16);
  }


  .#{$m-css-prefix}-EventList-title {
    margin-bottom: m-toRem($m-f8-16);

    span {
      font-size: 15px;
      line-height: 24px;
      text-transform: uppercase;
      color: #234796;
      font-weight: 800;
      display: inline-block;
      margin-bottom: 6px;
    }

    h3,
    h4 {
      font-size: 24px;
      line-height: 30px;
      color: #000a5a;
    }

    h4 {
      transition: all .25s linear;
      font-weight: 500;
    }

    a:visited,
    a {
      color: $aa-color-blue;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: $aa-color-orange;

        h3 {
          color: $aa-color-orange;
        }
      }
    }
  }

  .#{$m-css-prefix}-EventList-categorie {
    p {
      margin: 0;
    }

  }

  .#{$m-css-prefix}-EventList-btnWrap {

    .#{$m-css-prefix}-btn {
      // min-width: 12rem;

      @include media-breakpoint-up(lg) {
        // width: 100%;
      }
    }

    // a:first-child {
    //   margin-right: m-toRem($m-f8-8);
    // }

    @include media-breakpoint-down(sm) {
      a {
        margin-bottom: m-toRem($m-f8-8);
      }
    }

    @include media-breakpoint-up(lg) {
      a {
        margin-bottom: m-toRem($m-f8-8);
      }
    }
  }
}



// Gi
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-EventList-item.#{$m-css-prefix}-EventList-item--highlight {
  // border-left: 1rem solid $aa-color-blue;
  position: relative;
  padding-left: 1rem;

  // @include media-breakpoint-up(lg) {
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: linear-gradient(316.74deg, #234796 19.09%, #000a5a 72.24%);
  }

  // }
}
