//  reCAPTCHA Fahne unten rechts ausblenden
//------------------------------------------------------------------------------------------

.grecaptcha-badge {
  opacity: 0;
}

.#{$m-css-prefix}-reCaptcha {
	font-size: 0.875rem;
}
