.#{$m-css-prefix}-contactSticky {
  position: fixed;
  top: 18%;
  right: 0;
  z-index: 101;

  &-toggle {
    box-sizing: border-box;
    position: relative;
    width: 70px;
    height: 70px;
    background-color: #2A4891;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 0;
    top: 0;
    transition: 0.75s ease;

    &-icon-wrapper {
      background-color: #f0eeee;
      padding: 5px;
      border-radius: 50%;
    }
  }
  &-backdrop {
    background-color: #383a4240;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition: 0.75s ease-in-out;
    pointer-events: none;
  }
  &-wrapper {
    transition: 0.75s ease-in-out;

    &:hover {
      transform: translateX(-280px);
      .#{$m-css-prefix}-contactSticky-content {
        transform: translateX(0px);
      }

      .#{$m-css-prefix}-contactSticky-toggle {
        transform: translateX(0px);
        animation-name: slide;
        animation-duration: 0.5s;
        animation-delay: 0.85s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;

      }
      + .#{$m-css-prefix}-contactSticky-backdrop {
        opacity: 1;
      }
    }

    svg {
      fill: #2A4891;
      width: 30px;
      height: 30px;
    }
  }

  &-content {
    background-color: #efefef;
    transition: 0.75s ease;
    color: #000a5a;
    // transform: translateX(calc(0% + 70px));
    transform: translateX(70px);
    width: 350px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;

    @include media-breakpoint-up(lg) {
      & {
        transition: 0.75s ease;
      }
    }

    &-box {
      margin-bottom: 20px;

      h5 {
        margin-bottom: 0px;
        + a {
          margin-top: 6px;
          display: block;
        }
        + p {
          margin-top: 10px;
        }
      }
      p,
      a {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
  }

}


@keyframes slide {
  0% {
    left: 0;
  }

  100% {
    left: 70px;
  }
}
