
.#{$m-css-prefix}-Logo {
  width: auto;
  max-height: 100%;
}

.#{$m-css-prefix}-Logo-link {
  display: inline-block;
  position: relative;
  height: 64px;

  @include media-breakpoint-up(md) {
    width: 100%;
    padding-left: 1rem;
  }
}

// Modifiers
// -----------------------------------------------------------------------------


.#{$m-css-prefix}-Logo.#{$m-css-prefix}-Logo--mobile {
  width: auto;
  height: 3rem;
}


.#{$m-css-prefix}-Logo.#{$m-css-prefix}-Logo--footer {
  max-width: 118px;
  margin-bottom: m-toRem($m-f8-16);

  @include media-breakpoint-up(sm) {
    max-width: 130px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 150px;
  }
}
