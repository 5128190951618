.#{$m-css-prefix}-numbers {
  color: white;
  padding: 100px 0 42px;
  font-weight: 600;

  &-bg {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: linear-gradient(316.74deg, #234796 19.09%, #000a5a 72.24%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  &-item {
    font-size: 16px;
    text-align: center;
    margin-bottom: 58px;

    &:not(:last-child) {
      border-right: 2px solid rgba(255, 255, 255, .2);
    }
  }

  &-number {
    color: #F29F39;
    font-size: 80px;
    display: flex;
    justify-content: center;
  }
}
