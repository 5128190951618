.#{$m-css-prefix}-Search-pagination-resultsPerPage {
  float: right;

  form.aa-Form-group {
    display: flex;
    flex-direction: column;

    >p {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .aa-Form-group .form-control:not(.bootstrap-select){
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000a5a;
    padding: 0.75rem 0;

    &::after {
      right: 0;
    }

    .filter-option {
      display: flex;
      align-items: center;
    }
  }

  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;

  }
}

.#{$m-css-prefix}-Search-item {
  border: none;
  padding: 1rem 0;

  .#{$m-css-prefix}-Search-item-title {

    a {
      text-decoration: none;
    }
  }

  .results-highlight {
    background-color: #F29F39;
    border-radius: 0.2rem;
    padding: 0.075rem 0.3rem 0.175rem 0.3rem;
  }
}


.#{$m-css-prefix}-Search-pagination-nav {
  // justify-content: center;
  margin: 0 0 30px 0;

  >li {
    display: flex;
    padding: 10px;
    align-items: center;
  }
}

.#{$m-css-prefix}-Search-summary-text {
  padding-top: .75rem;
  padding-bottom: m-toRem($m-f8-8);
}

.#{$m-css-prefix}-Search-pagination-icon a:visited,
.#{$m-css-prefix}-Search-pagination-icon a {
  fill: $aa-color-blue;

  &:hover {
    fill: $aa-color-orange;
  }
}


.#{$m-css-prefix}-Search-pagination-icon svg {
  width: 3rem;
  height: 3rem;
  margin-top: 1px;
  padding: 1rem;
}

.#{$m-css-prefix}-Search-pagination-page,
.#{$m-css-prefix}-Search-pagination-page {

  a:visited,
  a {
    text-decoration: none;
    color: $aa-color-blue;
    font-weight: 600;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      color: $aa-color-orange;
    }
  }

  &.active a {
    pointer-events: none;
    color: $aa-color-gray-700;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 3px;
      background-color: #000a5a;
      position: absolute;
      bottom: -5px;
      left: 0;
    }
  }
}

.#{$m-css-prefix}-Search-field {

  .#{$m-css-prefix}-Search-input {
    border-bottom: 1px solid #000a5a;
    border-left: none;
    border-top: none;
    border-right: none;
    padding: 0.375rem 0.75rem 0.375rem 0;
    width: calc(50%);


    &:active,
    &:focus {
      outline: none;
      border-color: #DE9D00;
    }
  }

  .#{$m-css-prefix}-Search-btn {
    border: none;
    background: transparent;
    // background-color: $aa-color-orange;
    // background: $aa-color-yellow-gradient;
    width: 3rem;
    height: 2.5rem;
    padding: 0;
    position: relative;
    left: -40px;

    &:active,
    &:focus,
    &:hover {
      outline: none;

      svg {
        fill: #234796;
      }
    }


    svg {
      fill: #234796;
      padding: .7rem;
      width: 3rem;
      height: 100%;
    }


  }
}

// .search-form{
//   &-wrapper{

//   }
// }



body:not(.on-page-nav) .#{$m-css-prefix}-SearchForm-wrapper.#{$m-css-prefix}-SearchForm-wrapper--default,
body:not(.on-page-nav) .nav-item.uid-149.#{$m-css-prefix}-Navigation-nav-item {
  background: $aa-color-yellow-gradient;
  transition: $transition-base;
  cursor: pointer;
  overflow: hidden;

  &:focus,
  &:active,
  &:hover {
    color: $aa-color-blue;
    left: 0;

    .#{$m-css-prefix}-Icon--svg-search {
      fill: $aa-color-gray-300;
    }

    .#{$m-css-prefix}-SearchForm {
      width: calc(100% - 3rem);
      opacity: 1;
      z-index: 11;
    }
  }

  &:focus-within {
    color: $aa-color-blue;
    left: 0;

    .#{$m-css-prefix}-Icon--svg-search {
      fill: $aa-color-gray-300;
    }

    .#{$m-css-prefix}-SearchForm {
      width: calc(100% - 3rem);
      opacity: 1;
      z-index: 11;
    }
  }

  &:only-child {
    margin-left: 20rem;

    .#{$m-css-prefix}-SearchForm {
      width: calc(100% - 3rem);
      opacity: 1;
      z-index: 11;
    }
  }

  .#{$m-css-prefix}-SearchForm {
    position: absolute;
    width: 0;
    opacity: 0;
    height: 2.5rem;
    right: 3rem;
    z-index: -1;
    background-color: white;
    transition: $transition-base;
  }

  .#{$m-css-prefix}-SearchForm-textInput {
    float: right;
  }

  .#{$m-css-prefix}-SearchForm-iconWrapper {
    width: 3rem;
    height: 2.5rem;
  }

  .#{$m-css-prefix}-Icon--svg-search {
    width: 3rem;
    height: 100%;
    padding: .7rem;
    fill: white;
  }

}

body:not(.on-page-nav) .#{$m-css-prefix}-Navigation-sidebar-menu .nav-item.uid-149.#{$m-css-prefix}-Navigation-nav-item {
  background: transparent;
}


.on-page-nav .#{$m-css-prefix}-SearchForm-wrapper.#{$m-css-prefix}-SearchForm-wrapper--default {
  display: flex;
  align-items: center;

  .#{$m-css-prefix}-SearchForm {
    width: 11rem;

    .#{$m-css-prefix}-SearchForm-textInput {
      height: 2rem;
    }
  }

  svg {
    position: relative;
    width: 2rem;
    height: 2rem;
    padding: .5rem;
    background-color: $aa-color-orange;
    background: $aa-color-yellow-gradient;
    fill: white;
  }
}


.#{$m-css-prefix}-SearchForm {}


.#{$m-css-prefix}-SearchForm-textInput {

  height: 100%;
  border-top: 1px solid $aa-color-gray-300;
  border-left: 1px solid $aa-color-gray-300;
  border-bottom: 1px solid $aa-color-gray-300;
  border-radius: 0;

  @include media-breakpoint-up(lg) {

    font-size: 18px;
  }

  &::placeholder {
    line-height: 1rem;
  }

  &:focus {
    border-color: $aa-color-gray-300;
    box-shadow: none;
  }


}


// Modifiers
// -----------------------------------------------------------------------------

// Default on search results page
.#{$m-css-prefix}-SearchForm.#{$m-css-prefix}-SearchForm--default {

  .tx-indexedsearch-searchbox-sword {
    margin-right: m-toRem($m-f8-16);

    @include media-breakpoint-down(sm) {
      flex: 1 0 0px;
    }
  }
}

// Icon only
.#{$m-css-prefix}-SearchForm-wrapper.#{$m-css-prefix}-SearchForm-wrapper--min {
  position: relative;

  .#{$m-css-prefix}-SearchForm {
    display: block;
    width: 3em;
    height: 3em;
    background-color: $link-color;
    border-radius: 50%;

    @each $breakpoint in map-keys($m-logo-widths) {
      @include media-breakpoint-up($breakpoint) {
        $width: map-get($m-header-heights, $breakpoint) - m-toRem($m-f8-24);
        width: $width;
        height: $width;
        max-width: 3em;
        max-height: 3em;
        min-width: 30px;
        min-height: 30px;
      }
    }

    >svg {
      display: inline-block;
      width: 1.2em;
      height: 1.2em;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: white;
    }

    @include hover-focus {
      background-color: $link-hover-color;
    }
  }

  .#{$m-css-prefix}-SearchForm-backdrop {
    opacity: 0;
    transition: opacity $duration-normal linear;
  }
}


// Mobile
// -----------------------------------------------------------------------------

.#{$m-css-prefix}-SearchForm-wrapper.#{$m-css-prefix}-SearchForm-wrapper--mobile {
  padding-bottom: m-toRem($m-f8-16);
  border-bottom: 1px solid $aa-color-gray-300;

  .#{$m-css-prefix}-SearchForm-textInput {
    border-color: $aa-color-gray-300;
    border-radius: 0;
    border-right: none;
    width: calc(100% - 3rem);
    height: m-toRem(3*$m-f8-16);
    float: left;
  }

  .#{$m-css-prefix}-SearchForm-iconWrapper {
    padding: 0;
    margin: 0;
    border: none;

    .#{$m-css-prefix}-Icon--svg-search {
      position: relative;
      height: 3rem;
      padding: .75rem;
      width: 3rem;
      background-color: $aa-color-orange;
      background: $aa-color-yellow-gradient;
      fill: white;

    }

  }
}


// Icon only, mobile
.#{$m-css-prefix}-SearchForm-wrapper.#{$m-css-prefix}-SearchForm-wrapper--min.#{$m-css-prefix}-SearchForm-wrapper--mobile {
  align-self: stretch;
  margin-left: auto;

  @include media-breakpoint-up(md) {
    display: none;
  }

  .#{$m-css-prefix}-SearchForm {
    width: m-toRem($m-f8-64);
    height: 100%;
  }

  .#{$m-css-prefix}-SearchForm-textInput:focus {

    @include media-breakpoint-down(sm) {
      margin: m-toRem($m-f8-16) auto 0;
      padding: $input-padding-y $input-padding-x;
      font-size: $font-size-base;
      left: 0;
      right: 0;
      width: 80%;
    }

    @include media-breakpoint-only(xs) {
      top: map-get($m-header-heights, xs);
    }

    @include media-breakpoint-only(sm) {
      top: map-get($m-header-heights, xs);
    }
  }
}
