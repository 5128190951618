.#{$m-css-prefix}-Form {


  .dropdown-item,
  .#{$m-css-prefix}-Form-label,
  .form-check-label {
    margin: 0;
    line-height: 1.2rem;
    font-size: m-toRem(14px);
    color: $aa-color-gray-700;


    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }


  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: $aa-color-blue;
    text-decoration: none;
    background-color: white;
  }
}

.#{$m-css-prefix}-Form-group .form-control:not(.bootstrap-select) {
  border-radius: 0;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  border: 1px solid $aa-color-gray-300;
  border-bottom: 3px solid $aa-color-blue;
  font-size: m-toRem(14px);
  height: m-toRem(50px);
  padding: .75rem 1.5rem;

  &::-ms-expand {
    display: none;
  }

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.#{$m-css-prefix}-Form-group input.form-group-inner {

  position: relative;
  display: inline-block;

  &:after {
    content: " ";
    height: 0;
    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #F15922;
    position: absolute;
    right: 6px;
    top: 8px;
  }
}

.#{$m-css-prefix}-Form-group .form-group-inner textarea.form-control {

  height: m-toRem($m-f8-168);
  border-bottom: 3px solid $aa-color-orange;

  @include media-breakpoint-up(md) {
    height: m-toRem($m-f8-104);
  }
}

.#{$m-css-prefix}-Form-error-wrapper {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: $transition-base;
}

.#{$m-css-prefix}-Form-error {
  color: $aa-color-red;
  font-size: 0.75rem;

}

.has-error ~ .#{$m-css-prefix}-Form-error-wrapper,
.has-error + .#{$m-css-prefix}-Form-error-wrapper {
  max-height: 2rem;
  opacity: 1;
}

.has-error + .btn.dropdown-toggle.form-control.bs-placeholder,
.#{$m-css-prefix}-Form-group .form-control.has-error button,
.#{$m-css-prefix}-Form-group .form-control.has-error:not(.bootstrap-select) {
  border-bottom: 3px solid $aa-color-red;
}

.#{$m-css-prefix}-Form-nav {
  margin-bottom: m-toRem($m-f8-24);
}

.#{$m-css-prefix}-Form-nav-bar {
  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
  }
}


.#{$m-css-prefix}-Form-singleSelect .#{$m-css-prefix}-Form-singleSelect-wrapper {
  position: relative;


  svg {
    position: absolute;
    right: m-toRem($m-f8-16);
    width: m-toRem($m-f8-16);
    height: m-toRem($m-f8-16);
    top: 50%;
    transform: translateY(-50%);
    fill: $aa-color-gray-900;
    transition: $transition-base;
  }
}


.bootstrap-select {

  > .dropdown-menu {
    border-radius: 0;
    border: 1px solid $aa-color-gray-300;


    ul.dropdown-menu {
      position: relative;
    }
  }


  .dropdown-item {
    padding: .75rem 1.5rem;
  }


  .dropdown-toggle {
    &:after {
      border: none;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23464646' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E");
      position: absolute;
      width: .875rem;
      height: 1rem;
      top: calc(50% - .5rem);
      right: 1rem;
      transition: $transition-base;
    }
  }

  &.show {
    .dropdown-toggle {
      &:after {
        border: none;
        top: calc(50% - .25rem);
        transform: scaleY(-1);
      }
    }

    > .dropdown-menu {
      padding: 0;
    }

    &:not(.dropup) > .dropdown-menu {
      border-top: none;
      margin-top: -.25rem;
      border-bottom: 3px solid $aa-color-blue;


      ul.dropdown-menu {

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 1.5rem;
          right: 1.5rem;
          border-top: 1px solid $aa-color-gray-300;
          height: 1px;
          z-index: 1;
        }

        li:not(:last-child) {
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 1.5rem;
            right: 1.5rem;
            border-top: 1px solid $aa-color-gray-300;
            height: 1px;
          }
        }
      }
    }

    &.dropup > .dropdown-menu {
      border-bottom: none;
      margin-bottom: -.25rem;

      ul.dropdown-menu {

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 1.5rem;
          right: 1.5rem;
          border-top: 1px solid $aa-color-gray-300;
          height: 1px;
          z-index: 1;
        }

        li:not(:first-child) {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 1.5rem;
            right: 1.5rem;
            border-top: 1px solid $aa-color-gray-300;
            height: 1px;
          }
        }
      }
    }
  }


  &.show-tick .dropdown-menu .selected span.check-mark {
    right: 2rem;
    top: .75rem;
    color: $color-green-success;
  }
}

// Styling Step formular
//----------------------------------------------------------------------------------------------------------------------
.#{$m-css-prefix}-Form.#{$m-css-prefix}-Form--step {

  .title {
    display: none;

    &.current {
      display: block;
    }
  }

  .#{$m-css-prefix}-reCaptcha {
    display: none;
  }

  .g-recaptcha {
    display: none;
  }

  .steps {
    padding-bottom: m-toRem($m-f8-16);

    ul {
      @extend .nav;
      @extend .nav-pills;
    }

    li {
      @extend .nav-item;
      opacity: 1;
      transition: $transition-fade;

      a {
        @extend .#{$m-css-prefix}-btn;
        @extend .#{$m-css-prefix}-btn--blue;
        text-decoration: none;
        margin-right: m-toRem($m-f8-8);

      }
    }


    li.disabled {

      a {

        background: $aa-color-gray-300;
        pointer-events: none;

        &:after {
          display: none;
        }


        &:active,
        &:focus,
        &:hover {
          background-color: $aa-color-gray-300;
        }
      }
    }

    .current-info {
      display: none;
    }
  }

  .actions {

    ul {
      @extend .nav;
      justify-content: flex-end;

      li {
        margin-left: m-toRem($m-f8-16);
      }

      a {
        @extend .#{$m-css-prefix}-btn;

        &:last-child {

        }

        &[href="#finish"] {
          @extend .#{$m-css-prefix}-btn--yellow;

        }

        &[href="#previous"],
        &[href="#next"] {
          @extend .#{$m-css-prefix}-btn--blue;
        }
      }
    }
  }

}
