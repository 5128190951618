// Navigation toggle (Hamburger)
// Basics only, adjust for project

// Navigation toggle button
.#{$m-css-prefix}-Navigation-toggle-wrapper {
  display: block;
  position: fixed;
  z-index: $m-zIndex-4--navigationToggle;
  right: 0;
  top: 0;
  width: 65px;
  height: 65px;
  cursor: pointer;
  transition: background-color $duration-fast linear;
}

.#{$m-css-prefix}-Navigation-toggle {
  position: absolute;
  z-index: 1;
  right: m-toRem($grid-gutter-width);
  top: 1.5rem;
  width: 1.25rem;
  height: 1rem;
  cursor: pointer;
  fill: #000000;
  transition: top 0.7s ease, transform 0.7s ease;
  background-color: rgba(0, 0, 0, 0);
  transform-origin: left center;

  @include media-breakpoint-up(md){
    width: 1.125rem;
  }
  .is-showing-nav &,
  .is-showing-mobileNav & {
    transform: scale(1.5);
  }

  .#{$m-css-prefix}-Navigation-toggle-bar {
    height: 2px;
    background-color: $aa-color-blue;
    margin-top: 5px;
    transition: background-color 0.7s linear, transform 0.7s ease, opacity 0.3s ease 0.3s;
    transform-origin: left center;

    &:first-child {
      margin-top: 0;
    }

    // Navigation visible
    .is-showing-nav &,
    .is-showing-mobileNav & {

      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transition: background-color 0.7s linear, transform 0.7s ease, opacity 0.3s ease;
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}

body.is-showing-mobileNav {
  overflow: hidden;
}
