.#{$m-css-prefix}-teaser-big {
  background: #FFFFFF;
  box-shadow: 0px 0px 14px #e9ecf4;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    color: #000a5a;
    font-size: 22px;
    font-weight: 800;
    line-height: 40px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }

  .list-style-1 {
    line-height: 28px;

    li {
      position: relative;
      padding-left: 30px;
      margin-top: 8px;

      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='17' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.6056 1.14221C21.1807 0.716613 20.4914 0.71588 20.0665 1.1404L7.00865 14.1638L2.29741 9.04694C1.89032 8.60505 1.20204 8.57639 0.759408 8.98343C0.317119 9.39052 0.288803 10.0791 0.695894 10.5214L6.1745 16.4713C6.37514 16.6894 6.65594 16.816 6.95202 16.8222C6.95997 16.8225 6.96765 16.8225 6.97526 16.8225C7.26299 16.8225 7.53979 16.7082 7.7437 16.5051L21.6035 2.68161C22.0294 2.25715 22.0301 1.5678 21.6056 1.14221Z' fill='%232A4891'/%3E%3C/svg%3E");
        position: absolute;
        top: 6px;
        left: 0;
        width: 22px;
        height: 17px;
      }

      &::marker {
        content: '';
      }
    }
  }

  &-text {
    color: #000a5a;
    padding: 25px 20px 16px;

    @include media-breakpoint-up(lg) {
      padding: 40px;
    }

    p {
      text-align: center;
    }
  }

  &-image {
    &-wrapper {
      margin-top: auto;

      img {
        width: 100%;
      }
    }
  }

  &-link {
    &-wrapper {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
      margin-top: auto;
    }
  }

  .#{$m-css-prefix}-teaser-big {
    &-image-wrapper {
      +.#{$m-css-prefix}-teaser-big-link-wrapper {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
          bottom: 80px;
        }
      }
    }
  }
}
