.#{$m-css-prefix}-SiteWrapper {
  width: 100%;

  // Navigation backdrop
  &:before {
    content: '';
    display: block;
    position: fixed;
    z-index: $m-zIndex-4--fixedHeader - 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    background-image: linear-gradient(170deg, $aa-color-blue, darken($aa-color-blue, 20%));
    transition: all $duration-normal linear;
  }

  .is-showing-dropdown &,
  .is-showing-mobileNav & {

    &:before {
      visibility: visible;
      opacity: 0.65;
    }
  }
}


// Modifiers
// --------------------------------------------------------------------------------
.#{$m-css-prefix}-nav~ {

  .m-Main,
  .#{$m-css-prefix}-Main {
    @include media-breakpoint-down(lg) {
      margin-top: 65px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 111px;
    }

    &.active {
      margin-top: 0;
    }
  }
}

.fancybox-content {
  .aa-Main {
    margin-top: 0 !important;
  }
}
