.#{$m-css-prefix}-banner {
  margin: 40px 0;
  overflow: hidden;

  &-bg {
    &-blue {
      .#{$m-css-prefix}-banner {
        &-content-text {
          background: linear-gradient(316.74deg, #234796 19.09%, #000a5a 72.24%);
        }
      }
    }
  }

  &-inner {
    color: #fff;
  }

  &-content {
    position: relative;

    @include media-breakpoint-up(lg) {
      display: flex;
      min-height: 350px;
    }

    &-text {
      @include media-breakpoint-up(lg) {
        width: 60%;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 23px;
      }

      &-inner {

        p {
          font-size: 16px;
          line-height: 26px;

          a {
            &:not(:last-child) {
              margin-right: 16px;
              // margin-bottom: 10px;
            }
          }
        }
      }

      & {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &-image {
      @include media-breakpoint-up(lg) {
        width: 40%;
      }

      @include media-breakpoint-down(lg) {
        display: none;
      }

      .aa-banner-bg-image {

        svg {
          height: 100%;
        }

        @include media-breakpoint-up(lg) {
          min-height: 350px;
          position: absolute;
          right: 0;
          top: 20px;
          height: 100%;
        }

        @include media-breakpoint-down(lg) {
          position: absolute;
          top: 40px;
          left: 50%;
          width: 100%;
          height: 100%;
        }

        @include media-breakpoint-down(sm) {
          left: 20%;
        }
      }

      .aa-banner-image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
          // align-self: flex-start;
          // top: 0;
          // left: 0;

          align-self: flex-start;
          top: 0;
          right: 0;
          position: absolute;
          width: 40%;
        }
      }

    }
  }

  &-wide {
    @include media-breakpoint-up(lg) {
      background: linear-gradient(316.74deg, #234796 19.09%, #000a5a 72.24%);
    }

    .#{$m-css-prefix}-banner {
      &-content {
        &-text {
          @include media-breakpoint-down(lg) {
            padding: 56px 22px;
          }

          @include media-breakpoint-up(lg) {
            padding: 75px 30px 75px 0;
          }

        }
      }
    }

    .aa-banner-content-text {
      @include media-breakpoint-up(lg) {
        background: none;
      }
    }
  }

  &-narrow {
    .#{$m-css-prefix}-banner {
      &-content {
        &-text {
          @include media-breakpoint-up(lg) {
            padding: 0 30px 0 100px;
          }

          @include media-breakpoint-down(lg) {
            padding: 50px 22px;
          }
        }
      }
    }
  }
}
