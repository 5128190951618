.#{$m-css-prefix}-SubPageMenu {

}

.#{$m-css-prefix}-SubPageMenu-label {
  position: relative;
  cursor: pointer;
  color: $gray-300;
  padding: m-toRem($m-f8-4) 0;

  border-bottom: 2px solid $aa-color-orange;

  svg.#{$m-css-prefix}-Icon {
    position: absolute;
    right: m-toRem($m-f8-8);
    width: m-toRem($m-f8-16);
    height: m-toRem($m-f8-16);
    top: calc(50% - .5rem);
    fill: $aa-color-gray-900;
    transition: $transition-base;
  }

  &:hover {
    border-color: $aa-color-blue;
  }

  .#{$m-css-prefix}-SubPageMenu--isOpen & {
    border-color: $aa-color-blue;

    svg {
     transform: scaleY(-1);
    }
  }
}

.#{$m-css-prefix}-SubPageMenu-list {
  padding: 0;
  list-style: none;

  > li {
    padding: m-toRem($m-f8-4) m-toRem($m-f8-24);

    &:last-child {
      padding-bottom: 0;
    }
  }
}


.#{$m-css-prefix}-SubPageMenu-collapse {
  background: white;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  z-index: $zIndex-2;

  transition: $transition-base;

  @include media-breakpoint-up(md) {
    position: absolute;

    .#{$m-css-prefix}-Card & {
      margin-left: m-toRem(-$m-f8-24);
    }
  }

  .#{$m-css-prefix}-SubPageMenu--isOpen & {
    max-height: 30rem;
  }
}

.#{$m-css-prefix}-SubPageMenu-link {
  display: block;
  text-decoration: none;
  padding: .375rem 0;
  border-bottom: 1px solid $aa-color-gray-300;
  font-size: 1.1rem;
  width: 100%;
  color: $aa-color-gray-700;

  &:visited {
    color: $aa-color-gray-700;
  }

  &:hover {
    text-decoration: none;
    color: $aa-color-blue;
  }
}
