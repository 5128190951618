.#{$m-css-prefix}-Collapsible {
  background-color: white;
  position: relative;
  margin-bottom: m-toRem($m-f8-16);
  border-bottom: none;
  box-shadow: 0px 0px 14px #E9ECF4;

  &:hover {
    box-shadow: 0px 0px 4px #e9ecf4;
  }
}

button.#{$m-css-prefix}-Collapsible-btn:visited,
button.#{$m-css-prefix}-Collapsible-btn {
  width: 100%;
  text-decoration: none;
  text-align: left;
  background-color: #ffffff;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #000a5a;
  font-weight: 600;
  border-color: transparent;
  transition: border-bottom-color .2s linear .2s;
  position: relative;
  letter-spacing: 0.5px;
  padding: 24px;

  @include media-breakpoint-down(md) {
    padding: 12px 60px 12px 24px;
  }

  &::before {
    content: '';
    width: 8px;
    height: 100%;
    display: block;
    position: absolute;
    left: -2px;
    top: 0;
    background: #032578;
  }


  svg {
    transform: scaleY(-1);
    position: absolute;
    right: m-toRem($m-f8-16);
    width: 38px;
    height: 30px;
    top: calc(50% - 15px);
    fill: #234796;
    transition: $transition-base;
  }


  &.collapsed {
    background: none;

    &::before {
      background: #F29F39;
    }

    svg {
      transform: scaleY(1);
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.#{$m-css-prefix}-Collapsible-content {

  .collapsing,
  .collapse {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: #032578;
      width: 8px;
      height: 0;
      transition: 0.35s ease;
    }
  }

  .collapse {

    &.show {
      &::before {
        height: 100%;
      }
    }
  }
}

.#{$m-css-prefix}-Collapsible-body {
  @include media-breakpoint-up(md) {
    padding: 0 50px 0 0;
  }

  .ce-bodytext p {
    color: $aa-color-gray-700;
  }

  >* {
    padding: 1rem;
    transition: border-bottom-color linear .2s;
    border-bottom: 2px solid transparent;
  }
}
