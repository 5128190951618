.#{$m-css-prefix}-Carousel {
  width: 100%;
  position: relative;


  .#{$m-css-prefix}-Carousel-slides {
    .slick-slide>div {
      height: 100%;
    }
  }

  .#{$m-css-prefix}-Card {
    padding: m-toRem($m-f8-16) m-toRem($m-f8-16) 3rem m-toRem($m-f8-16);
    margin: 0;
    height: 100%;
    position: relative;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }


  .#{$m-css-prefix}-Carousel-nav-dots-wrapper {
    position: relative;
    // padding-top: m-toRem($m-f8-24);
    padding-bottom: m-toRem($m-f8-16);

    @include media-breakpoint-down(lg) {
      >.container {
        padding: 0;
      }
    }

    .slick-dots {
      position: relative;
      bottom: 0;

      @include media-breakpoint-down(lg) {
        text-align: right;
      }
    }
  }
  &-newsTeaser {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;

    .slick-track {
      margin-bottom: 15px;
      display: flex;
      .slick-slide{
        height: inherit;
      }
    }
    .#{$m-css-prefix}-newsteaser-item {
      height: 100%;
    }
  }
}

.#{$m-css-prefix}-Carousel-arrow {
  cursor: pointer;
  transition: $transition-base;
  padding: m-toRem($m-f8-8);

  @include media-breakpoint-up(xl) {
    padding: m-toRem($m-f8-24);
  }

  svg {
    transition: $transition-base;
    fill: white;
    stroke: $aa-color-rgba-blue;
    width: m-toRem($m-f8-16);
    height: m-toRem($m-f8-16*2);

    @include media-breakpoint-up(xl) {
      width: m-toRem($m-f8-24);
      height: m-toRem($m-f8-64);
    }
  }

  &:hover {
    svg {
      fill: $aa-color-blue;
      stroke: $aa-color-blue;
      filter: drop-shadow(0 0 20px $aa-color-rgba-blue);
    }
  }

  &--next {
    right: 0;
    top: 25%;
    transform: translateY(-50%);
  }

  &--prev {
    left: 0;
    top: 25%;
    transform: translateY(-50%) rotate(180deg);
  }
}

.aa-Testemonials--slider {

  .#{$m-css-prefix}-Carousel-arrow {
    position: absolute;
    top: calc(50% - 120px);

    @include media-breakpoint-up(sm) {
      & {
        top: calc(50% - 90px);
      }
    }

    @include media-breakpoint-up(ms) {
      & {
        top: calc(50% - 90px);
      }
    }

    @include media-breakpoint-up(lg) {
      top: 40%;
    }
  }
}

.aa-Testemonials--slider,
.aa-Testemonials--quote {

  .aa-Carousel-arrows {
    .aa-Carousel-arrow {
      width: 46px;
      height: 46px;
      background: #FFFFFF;
      border-radius: 50%;
      padding: 0;
      border: 2px solid #234796;

      &:hover {
        box-shadow: 0px 0px 5px rgba(0, 60, 122, 0.4);
        background-color: transparent;
      }

      svg {
        fill: #09509C;
        stroke: none;
        width: 18px;
        height: 27px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .aa-Carousel-arrow--prev {
      left: 3%;

      @include media-breakpoint-up(lg) {
        left: 28%;
      }

      svg {
        margin-left: 1px;
      }
    }

    .aa-Carousel-arrow--next {
      right: 3%;

      @include media-breakpoint-up(lg) {
        right: 28%;
      }

      svg {
        margin-left: 1px;
      }
    }
  }

}


.aa-Testemonials--slider {
  .aa-Carousel-arrows {
    .aa-Carousel-arrow {
      box-shadow: 0px 0px 14px #81889A;
    }
  }
}

.aa-Testemonials--quote {
  .aa-Carousel-arrows {
    display: flex;

    .aa-Carousel-arrow--prev {
      margin-right: 16px;
    }
  }
}

.#{$m-css-prefix}-Carousel-nav-dots {
  position: relative;

  &-wrapper {
    position: absolute;
    z-index: $zIndex-1;
    bottom: 0;
    width: 100%;
  }

  .slick-dots {
    bottom: m-toRem($m-f8-24);
    text-align: right;

    @include media-breakpoint-down(sm) {
      bottom: m-toRem($m-f8-8);
      text-align: left;
    }

    li {
      width: 33px;
      height: 4px;
      margin: 0 3px;

      @include media-breakpoint-up(lg) {
        width: 63px;
      }

      button {
        width: 100%;
        padding: 0;
        height: 0;
        border-bottom: 4px solid rgba(9, 26, 66, 0.15);
        border-radius: 6px;

        &:before {
          content: none;
        }
      }

      &:not(.slick-active) {
        &:hover {
          button {
            border-bottom: 4px solid $aa-color-blue;
            box-shadow: $aa-box-shadow-blue;
          }
        }
      }

      &.slick-active button {
        border-bottom: 4px solid #234796;
      }
    }
  }
}

.aa-Title--list--mediatistestimonials_pi1 {

  @at-root {
    h1#{&} {
      font-size: 1.65rem;

      @include media-breakpoint-up(lg) {
        font-size: 2.25rem;
      }
    }

    h2#{&} {
      font-size: 1.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    h3#{&} {
      font-size: 1.4rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
      }
    }

    h4#{&} {
      font-size: 1.3rem !important;
    }

    h5#{&} {
      font-size: 1.2rem;
    }
  }
}

.aa-Testemonials--slider {
  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }

  .aa-Carousel-slide {
    >.aa-Card {
      >.aa-Card-content {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(9, 26, 66, 0.15);
      }
    }
  }

  .aa-Card--slider {
    &.aa-Card {
      padding: 1rem 0 0rem 1rem;
    }

    .aa-Card-image-wrapper {
      svg {
        width: 65px;
        height: auto;
      }
    }

    .aa-Card-image-wrapper {
      position: relative;

      a.various,
      span.various {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
          pointer-events: none;
        }
      }
    }

    .aa-Card-content {
      .aa-Card-link {
        a.aa-btn {
          @include media-breakpoint-up(lg) {
            pointer-events: none;
          }
        }
      }
    }
  }

  .card-title {
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: #4E4E4E;
      margin-bottom: 16px;
    }
  }

  .slick-active {
    filter: drop-shadow(0px 0px 24px rgba(0, 60, 122, 0.25));

    @include media-breakpoint-up(lg) {
      // opacity: 0.4;
    }
  }

  .slick-active.slick-current+.slick-active {
    @include media-breakpoint-up(lg) {
      opacity: 1;
    }

    a.various,
    span.various {
      @include media-breakpoint-up(lg) {
        pointer-events: all;
      }
    }

    .aa-Card-content {
      .aa-Card-link {
        a.aa-btn {
          @include media-breakpoint-up(lg) {
            pointer-events: all;
          }
        }
      }
    }
  }

  .aa-Card-image-wrapper .card-img-top {
    border: 8px solid #FFFFFF;
  }

  .aa-Carousel-nav-dots-wrapper {
    margin-top: 20px;
  }

  .aa-Carousel-wrapper {
    @include media-breakpoint-up(lg) {
      position: relative;
      left: -10%;
      width: 120%;
    }
  }
}

.aa-Testemonials--quote {
  @include media-breakpoint-down(sm) {
    margin-top: 70px;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 20px;
  }

  .#{$m-css-prefix}-Card-image-wrapper .card-img-top {
    border-radius: 50%;
  }

  .aa-Carousel {
    &-wrapper {
      position: relative;
    }

    &-arrow {
      padding: 0;
    }

    &-arrows {
      position: absolute;
      top: -35px;
      right: 30px;

      &-newsTeaser {
        right: 0;
      }
    }
  }

  .aa-Carousel .aa-Card {
    &--quote {
      padding: 0 0 0;

      @include media-breakpoint-up(lg) {
        padding: 0 0 40px;
      }
    }
  }

  .aa-Card {
    &--quote {
      border-top: 1px solid rgba(9, 26, 66, 0.2);
      border-bottom: 1px solid rgba(9, 26, 66, 0.2);
    }

    &-image {
      &-wrapper {
        position: relative;

        @include media-breakpoint-down(lg) {
          padding-bottom: 40px;
        }

        @include media-breakpoint-up(lg) {
          width: 300px;
        }

        img {
          object-fit: cover;
          border-radius: 50%;
          position: relative;
          z-index: 10;
          margin-left: 50px;

          @include media-breakpoint-up(lg) {
            margin-left: 100px;
          }

          @include media-breakpoint-up(lg) {
            height: 172px;
            width: 172px;
            min-width: 172px;
          }

          @include media-breakpoint-down(lg) {
            height: 112px;
            width: 112px;
            min-width: 112px;
          }
        }

        &::before {
          content: '';
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA4IiBoZWlnaHQ9IjExMiIgdmlld0JveD0iMCAwIDEwOCAxMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjU4MzAwOCAzMS40Mjg3VjAuNzkyMDUzTDQ5LjI1ODEgNDAuNTkxMVY3Mi4wODY3TDAuNTgzMDA4IDExMS44ODZWODEuMjQ5TDMxLjIxOTcgNTYuMzM4OUwwLjU4MzAwOCAzMS40Mjg3Wk01OS4yNzk0IDAuNzkyMDUzTDEwNy45NTQgNDAuNTkxMVY3Mi4wODY3TDU5LjI3OTQgMTExLjg4NlY4MS4yNDlMODkuOTE2IDU2LjMzODlMNTkuMjc5NCAzMS40Mjg3VjAuNzkyMDUzWiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzk4MV81MTE5KSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzk4MV81MTE5IiB4MT0iNjUuNjYyMyIgeTE9IjExMS44ODYiIHgyPSI4LjM5MTE2IiB5Mj0iNTMuMDU4MSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjMkE0ODkxIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzAzMjU3OCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          position: absolute;

          @include media-breakpoint-down(lg) {
            left: 0;
            width: 70px;
            height: 70px;
            bottom: 20px;
          }

          @include media-breakpoint-up(lg) {
            left: 20px;
            width: 100px;
            height: 100px;
            bottom: -20px;
          }
        }

      }
    }

    &-content {
      @include media-breakpoint-up(lg) {
        margin-left: 50px;
      }

      .card-title {
        padding-top: 0;

        h3 {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          color: #000a5a;
        }
      }

      .card-body {
        padding: 0;
      }

      .card-text {
        margin-bottom: 20px;

        p,
        p a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #000a5a;
        }
      }

      &-wrapper {
        padding: 40px 0 20px 0;

        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
}



// Slider Preview Styling
//----------------------------------------------------------------------------------------------------------------------
.#{$m-css-prefix}-Carousel-preview {
  position: absolute;
  top: 50%;
  right: m-toRem($m-f8-64);
  transform: translateY(-50%);
  width: m-toRem($m-f8-104);
  height: m-toRem($m-f8-104);
  border-radius: 50%;
  border: m-toRem($m-f8-4) solid white;
  background-image: url("../../Images/previewpicture.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: $transition-base;
  display: none;

  @include media-breakpoint-up(xl) {
    display: block;
  }

}

.#{$m-css-prefix}-Carousel-arrow.#{$m-css-prefix}-Carousel-arrow--preview {

  @include media-breakpoint-up(xl) {
    padding-left: m-toRem($m-f8-104);

    &:hover {
      .#{$m-css-prefix}-Carousel-preview {
        border-color: $aa-color-blue;
        box-shadow: $aa-box-shadow-blue;
      }
    }
  }
}

// Carousel Slider Padding und Margin-Anpassungen
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Carousel {
  .#{$m-css-prefix}-Card {
    padding: 1rem 0 3rem 1rem;
    margin: 0;
    height: 100%;
    position: relative;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
}
