.#{$m-css-prefix}-Footer {
  background-color: #000a5a;
  color: white;
  padding-top: m-toRem($m-f8-64);
  padding-bottom: m-toRem($m-f8-8);
  font-size: 14px;

  a:hover {
    text-decoration: underline;
  }

  &-col {
    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: #DE9D00;
      }
    }
  }

  &-logo {
    width: 200px;
  }

  .nav-link {
    color: white;

    &:visited {
      color: white;
    }

    &-icon {
      font-weight: 600;
      display: flex;
      padding: 0;

      .aa-Icon {
        fill: #F99705;
        transform: rotate(-90deg);
        height: 16px;
        width: 11px;
        margin-right: m-toRem($m-f8-4);
        margin-top: 2px;
      }
    }
  }

  .#{$m-css-prefix}-Icon--socialmedia--wrapper {
    background: linear-gradient(269.41deg, #2A4891 0.51%, #032578 99.39%);
    border-radius: 40px;
    margin-right: m-toRem($m-f8-4);
    height: m-toRem(32px);
    width: m-toRem(32px);
    align-items: center;
    display: inline-flex;
    justify-content: center;

    .aa-Icon {
      height: m-toRem(16px);
      width: m-toRem(16px);
      fill: #fff;
      position: relative;
      top: -1px;
      &#linkedin {
        height: m-toRem(28px);
        width: m-toRem(28px);
      }
    }
  }
}

.#{$m-css-prefix}-Footer-section {
  margin: m-toRem($m-f8-24) 0;

  &-title {
    color: white;
    font-size: $h6-font-size;
  }

  &-detail {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

// .#{$m-css-prefix}-Footer-bar {
//   background-color: white;
//   margin: .5rem 0;
// }

.#{$m-css-prefix}-Footer-nav {
  // justify-content-end
  justify-content: inherit !important;
}

.#{$m-css-prefix}-Footer-copyright {
  display: block;
  padding: 0.5rem 1rem 0.5rem 0;
  margin-bottom: 0;
}

.#{$m-css-prefix}-Footer-nav-item {

  position: relative;

  .nav-link {
    padding-right: 10px;
    padding-left: 10px;
  }

  &:first-child .nav-link {
    padding-left: 0;
  }

  &:not(:last-child):after {
    content: '';
    position: absolute;
    width: 1px;
    height: 1em;
    top: .9em;
    right: 0;
    border-left: 1px solid white;
  }
}

// Modifiers
// ----------------------------------------------------------------------

// Switch footer
.#{$m-css-prefix}-Footer.#{$m-css-prefix}-Footer--switch {


  .#{$m-css-prefix}-Footer-phone,
  .#{$m-css-prefix}-Footer-mail,
  .#{$m-css-prefix}-Footer-fax {
    display: inline-block;

    &:before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: m-toRem($m-f8-8);
      background-image: url($aa-icon-phone-white);
      background-size: contain;
      background-repeat: no-repeat;
    }

  }

  .#{$m-css-prefix}-Footer-mail {
    &:before {
      background-image: url($aa-icon-mail-white);
      background-position: 0 m-toRem($m-f8-2);
    }
  }

  .#{$m-css-prefix}-Footer-fax {
    &:before {
      background-image: url($aa-icon-fax-white);
    }
  }
}


// Custom element Styling
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Footer .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--white:hover {
  border-color: white;
}
