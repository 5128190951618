body:not(.on-page-nav) .#{$m-css-prefix}-Navigation {

  background-color: $aa-color-blue;
  color: white;

  > ul {
    position: relative;
    flex-direction: row;
  }

  li:first-child > * {
    padding-left: 0;
  }


  .nav-link,
  .#{$m-css-prefix}-Navigation-fakeLink,
  a {
    color: white;
    transition: all .15s linear;
    text-decoration: none;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: 500;

    &:visited {
      color: white;
    }

    &:first-child {
      padding-left: 0;
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;

    }
  }
}


.#{$m-css-prefix}-Navigation-bar {
  justify-content: flex-start;
  align-items: flex-start;
}

.#{$m-css-prefix}-Navigation-nav-item {
  padding-right: m-toRem($m-f8-16);
  padding-left: m-toRem($m-f8-16);
  position: relative;


}

.#{$m-css-prefix}-Navigation-fakeLink,
.#{$m-css-prefix}-Navigation-fakeLink:visited,
.nav-link:visited,
.nav-link {
  text-decoration: none;
  cursor: pointer;
  transition: all .15s linear;
  color: $aa-color-blue;
  font-weight: 500;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: $aa-color-orange;
  }
}


.#{$m-css-prefix}-Navigation-fakeLink {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}


.#{$m-css-prefix}-Navigation-nav-item--hasSubs .#{$m-css-prefix}-Navigation-fakeLink {

  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: m-toRem(10px);
    height: 2rem;
    fill: currentColor;
    transition: $transition-base;

  }
}


.#{$m-css-prefix}-Navigation-nav-item--hasSubs.is-active .#{$m-css-prefix}-Navigation-fakeLink svg {
  transform: scaleY(-1) translateY(50%);
}


// Mobile Nav
// ---------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Navigation-sidebar {
  width: 100%;
  top: m-toRem($m-f8-64);
  right: 0;
  bottom: 0;
  z-index: 1030;
  position: fixed;
  transform: translateX(100%);
  padding: m-toRem($m-f8-16) m-toRem($grid-gutter-width) m-toRem($m-f8-64);
  transition: all .4s linear;
  background: white;

  @include media-breakpoint-up(sm) {
    width: 50%;
    min-width: 320px;
    max-width: 400px;
  }
}

.is-showing-mobileNav .#{$m-css-prefix}-Navigation-sidebar {

  transform: translateX(0);
}


.#{$m-css-prefix}-Navigation-sidebar.#{$m-css-prefix}-Navigation-sidebar--mobile {

  .#{$m-css-prefix}-Navigation-sidebar-menu {
    position: relative;
    overflow: auto;
    height: calc(100% - #{m-toRem(80px)});
  }

  .#{$m-css-prefix}-Navigation-nav-item {
    padding: 0;
    border-bottom: 1px solid $aa-color-gray-300;
    list-style: none;

    .#{$m-css-prefix}-Navigation-fakeLink {
      padding: .5rem 0;
      font-weight: 500;
      margin-bottom: 0;
      border-bottom: 1px solid $aa-color-gray-300;

      svg {
        width: m-toRem(14px);
        fill: $aa-color-orange;
      }
    }

    .nav-link {
      text-decoration: none;
      font-size: 1.25rem;
      font-weight: 500;

      &:visited {
        color: $aa-color-blue;
      }
    }

    .#{$m-css-prefix}-Navigation-subnav-item .nav-link {
      font-size: 1rem;
      color: $aa-color-gray-700;
    }

    &.active {
      .nav-link {
        color: $aa-color-gray-700;
      }
    }

    &.#{$m-css-prefix}-Navigation-nav-item--hasSubs {
      color: $aa-color-blue;
      font-size: 1.25rem;
      max-height: 2.875rem;
      overflow: hidden;
      transition: max-height .2s ease-in-out;

      &.sub-is-open {
        max-height: 50rem;
      }

      &.sub-is-open {
        svg {
          transform: scaleY(-1) translateY(50%);
        }
      }
    }
  }

  .#{$m-css-prefix}-Navigation-droppdown {
    padding: 0 1rem;
  }
}


.#{$m-css-prefix}-Navigation-backdrop.#{$m-css-prefix}-Navigation-backdrop--mobile {
  position: fixed;
  top: m-toRem($m-f8-64);
  left: 0;
  bottom: 0;
  right: 50%;
  z-index: 1000;
  display: none;
}

.is-showing-mobileNav .#{$m-css-prefix}-Navigation-backdrop.#{$m-css-prefix}-Navigation-backdrop--mobile {
  display: block;
}

.#{$m-css-prefix}-Navigation-contact {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
  background: $aa-color-blue;
  margin: 0;
  padding: m-toRem($m-f8-16) m-toRem($grid-gutter-width) m-toRem($m-f8-8 + $m-f8-4);
  font-size: 1.25rem;
  color: white;
  line-height: 1.2;
  display: flex;
  justify-content: space-between;

  .#{$m-css-prefix}-Navigation-contact-icon {
    position: relative;
    width: 2rem;
    height: auto;

    svg {
      color: #fff;
    }
  }
}


// SubNavigation
// ---------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Navigation-subNav-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: .2s all .2s ease-in-out;
}


.nav-droppdown-active .#{$m-css-prefix}-Header .#{$m-css-prefix}-Navigation-subNav-wrapper {
  max-height: 800px;
  padding-bottom: m-toRem($m-f8-24);
  transition: all .2s ease-in-out;
}

.#{$m-css-prefix}-Navbar-subNav {

  display: none;
  border-bottom: 1px solid $aa-color-gray-300;
  opacity: 0;
  transition: all .2s ease-in-out;


  .nav-link:visited,
  .nav-link,
  .#{$m-css-prefix}-Navigation-fakeLink:visited,
  .#{$m-css-prefix}-Navigation-fakeLink {
    color: $aa-color-gray-900;

    &:hover,
    &:active,
    &:focus {
      color: $aa-color-blue;
    }

  }

  .#{$m-css-prefix}-Navbar-subNav-headline {
    font-size: $h2-font-size;
    color: $aa-color-blue;
    font-weight: 600;
    margin: 0 ;
    padding: 0 0 m-toRem($m-f8-16) 0;
    width: 100%;
    height: m-toRem($m-f8-64);
    border-bottom: 1px solid $aa-color-gray-300;
  }

  &.is-active {
    opacity: 1;
    transition: .2s all .2s ease-in-out;
  }
}

.#{$m-css-prefix}-Navigation-subNav-item {
  display: flex;
  flex: 1 1 calc(50% - .5rem);;
  margin: 0 ;
  border-bottom: 1px solid $aa-color-gray-300;
  position: relative;
  padding-left: m-toRem($m-f8-16*2);

  svg {
    margin-right: m-toRem($m-f8-8);
    fill: currentColor;
    width: 1.25rem;
    height: 25px;
    position: absolute;
    left: 0;
  }

  &:nth-child(2n+1) {
    margin-right: m-toRem($m-f8-16);
  }


  &:last-child,
  &:nth-last-of-type(-n+2):not(:nth-child(even)) {
    border-bottom: none;
  }
}

a.#{$m-css-prefix}-Navigation-subnav-link {
  text-decoration: none;
  color: $aa-color-blue;
  padding-right: 0;
  padding-left: 0;

  &:visited {
    color: $aa-color-blue;
  }

  &:hover {
    color: $aa-color-orange;
  }

}


// Modifiers


// Main navigation
.#{$m-css-prefix}-Navigation.#{$m-css-prefix}-Navigation--main {


}

// Footer navigation
.#{$m-css-prefix}-Navigation.#{$m-css-prefix}-Navigation--footer {
}


.on-page-nav {

  .#{$m-css-prefix}-Header {
    background-color: white;
  }


  .#{$m-css-prefix}-Navigation-nav-item {
    &:first-child {
      padding-left: 0;

      .#{$m-css-prefix}-Navigation-fakeLink {
        padding-left: 0;
      }
    }

    span {
      white-space: nowrap;

    }
  }
}

// Navigation Highlight
// ---------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Navigation-highlight .#{$m-css-prefix}-Title {

  height: m-toRem($m-f8-40);

}
