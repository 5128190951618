// Button with icon only
.#{$m-css-prefix}-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  width: m-toRem($m-f8-40);
  height: m-toRem($m-f8-40);
}


// Toggle button
.#{$m-css-prefix}-btn-toggle {

  &:focus,
  &:active:focus {
    box-shadow: none !important;
  }

  &:before {
    transition: transform 0.35s ease;
  }

  &.collapsed:before {
    transform: rotate(180deg);
  }

  // For collapsing content
  &.#{$m-css-prefix}-btn-toggle--collapse {

    &:before {
      // @include fa-icon;
      // @extend .fas;
      // content: fa-content($fa-var-angle-up);
      margin-right: m-toRem($m-f8-4);
      vertical-align: 0;
    }
  }
}

.#{$m-css-prefix}-btn {
  @extend .btn;
  position: relative;

  // height: m-toRem(50px);
  padding: m-toRem(16px) m-toRem(25px) m-toRem(16px) m-toRem(25px);

  text-decoration: none;
  white-space: normal;

  font-size: 16px;
  line-height: 16px;
  font-weight: 600;

  &:hover {
    text-decoration: none !important;
  }

  svg {
    fill: currentColor;
    width: 1rem;
    height: 1rem;
  }
}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--arrow {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: #234796;
  padding: 5px 0 10px 0;
  margin: 0;
  background-image: linear-gradient(0deg, #f29f39, #f29f39);
  background-size: 100% 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;

  &::before {
    content: '';
  }

  &::before {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUw4IDhMMTUgMSIgc3Ryb2tlPSIjMkE0ODkxIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
    transform: rotate(-90deg);
    display: inline-block;
    width: 10px;
    height: 7px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: -2px;
    margin-right: 8px;
  }
}

// btn arrow variant for text inline
// ---------------------------------------------------------------------------------------------------------------------
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--arrow.#{$m-css-prefix}-btn--inline {
  margin: 0;
  padding: 0 0 3px;
  font-size: inherit;
  font-weight: 500;
  display: inline;
  background-size: 100% 2px;
  background-position: 0 22px;
  top: -1.5px;

  @include media-breakpoint-down(sm) {
    background-position: 0 20px;
  }

}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--primary,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--yellow,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--blue {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: #fff;
  border: 2px solid transparent;
  background: linear-gradient(269.41deg, #2A4891 0.51%, #032578 99.39%);
  padding: 14px 24px;
  border-radius: 40px;
  background-origin: border-box;

  &:hover {
    background: transparent;
    border: 2px solid #234796;
    color: #234796;
  }

  &:active,
  &:focus {
    background: transparent;
    border: 2px solid #234796;
    color: #234796;
    box-shadow: 0px 0px 6px rgba(9, 26, 66, 0.25);
  }
}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--arrow {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: #234796;
  padding: 5px 0 10px 0;
  margin: 0;

  // &::before,
  // &::after {
  //   content: '';
  // }

  // &::before {
  //   width: 100%;
  //   height: 3px;
  //   background-color: #F29F39;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  // }

  &::before {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUw4IDhMMTUgMSIgc3Ryb2tlPSIjMkE0ODkxIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
    transform: rotate(-90deg);
    display: inline-block;
    width: 10px;
    height: 7px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: -2px;
  }
}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--large {
  padding: 19px 24px;
}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--wide {
  padding: 14px 142px;
}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--wide.#{$m-css-prefix}-btn--large {
  padding: 20px 142px;
}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--secondary,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--blue,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--blue:visited {
  border-radius: 40px;
  color: #234796;
  background-color: transparent;
  padding: 14px 18px;
  border: 2px solid #234796;
  background-origin: border-box;

  &:hover {
    color: #000a5a;
    background-color: transparent;
    border: 2px solid #000a5a;
  }

  &:focus,
  &:active {
    color: #000a5a;
    background-color: transparent;
    border: 2px solid #000a5a;
    box-shadow: 0px 0px 6px rgba(9, 26, 66, 0.25);
  }

}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--secondary,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--white,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--white:visited {
  border-radius: 40px;
  color: #234796;
  background-color: transparent;
  padding: 14px 18px;
  border: 2px solid #234796;
  background-origin: border-box;

  &:hover {
    color: #000a5a;
    background-color: transparent;
    border: 2px solid #000a5a;
  }

  &:focus,
  &:active {
    color: #000a5a;
    background-color: transparent;
    border: 2px solid #000a5a;
    box-shadow: 0px 0px 6px rgba(9, 26, 66, 0.25);
  }
}

.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--secondary,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--lightBlue,
.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--lightBlue:visited {
  border-radius: 40px;
  color: #234796;
  background-color: transparent;
  padding: 14px 18px;
  border: 2px solid #234796;
  background-origin: border-box;

  &:hover {
    color: #000a5a;
    background-color: transparent;
    border: 2px solid #000a5a;
  }

  &:focus,
  &:active {
    color: #000a5a;
    background-color: transparent;
    border: 2px solid #000a5a;
    box-shadow: 0px 0px 6px rgba(9, 26, 66, 0.25);
  }
}


// Create Btn with mixin
// ------------------------------------------------------------------------------------------


@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    white-space: normal;
    @include button-variant($value, white);
  }
}

@each $color,
$value in $theme-colors {
  .btn-outline-#{$color} {
    white-space: normal;
    @include button-outline-variant($value, white);
  }
}


// Buttons in context
// ------------------------------------------------------------------------------------------

.#{$m-css-prefix}-DecisionHub {
  .#{$m-css-prefix}-btn {
    text-align: left;
  }

  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--pharma,
  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--blue {

    svg {
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: .5rem;
      top: 50%;
      transform: translateY(-50%);
      fill: white;
    }
  }

  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--lightBlue {
    position: relative;

    svg {
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: .5rem;
      top: 50%;
      transform: translateY(-50%);
      fill: $aa-color-blue;
    }

    &:hover {
      svg {
        fill: white;
      }
    }
  }
}

.#{$m-css-prefix}-DecisionHub.#{$m-css-prefix}-DecisionHub--aerzte .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--lightBlue {
  padding: m-toRem(17px) 1rem m-toRem(17px) .5rem;
}

.#{$m-css-prefix}-DecisionHub.#{$m-css-prefix}-DecisionHub--aerzte .#{$m-css-prefix}-btn {

  padding: 1.125rem 1rem 1.125rem .5rem;

}

.#{$m-css-prefix}-DecisionHub.#{$m-css-prefix}-DecisionHub--apotheker .#{$m-css-prefix}-btn-wrap {

  .#{$m-css-prefix}-btn--pharma {
    height: 5.125rem;
    padding: 2rem 2rem 2rem .75rem;
    // font-size: m-toRem(22px);

    @include media-breakpoint-down(sm) {
      height: 3.625rem;
      padding: 1.375rem 2rem 1.25rem .7rem;
      font-size: 1rem;
    }
  }

  // &:nth-child(1) {

  //   .#{$m-css-prefix}-btn--pharma:after {
  //     background-color: $aa-color-zyto;
  //   }
  // }

  // &:nth-child(2) {

  //   .#{$m-css-prefix}-btn--pharma:after {
  //     background-color: $aa-color-kra;
  //   }
  // }

  // &:nth-child(3) {

  //   .#{$m-css-prefix}-btn--pharma:after {
  //     background-color: $aa-color-gray-600;
  //   }
  // }
}


.#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--small {
  padding: .625rem 1rem;
}

// Within CE Text
.frame-type-text,
.frame-type-textpic,
.frame-type-textmedia,
.#{$m-css-prefix}-Card-text {

  [class*="btn-"] {
    margin: 0.75em 1.5em 0.75em 0;

    &:only-child {
      margin-right: 0;
    }
  }
}

.frame-type-textmedia {
  .ce-bodytext {
    .text-center {
      button.aa-btn {
        margin: 0 auto;
      }
    }

    .text-right {
      button.aa-btn {
        margin-left: auto;
      }
    }
  }
}

// protected btns
// ---------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-btn.#{$m-css-prefix}-Protected-btn {
  position: relative;
  padding-left: m-toRem($m-f8-40);

  &:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23fff'%3E%3Cpath d='M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM264 392c0 22.1-17.9 40-40 40s-40-17.9-40-40v-48c0-22.1 17.9-40 40-40s40 17.9 40 40v48zm32-168H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z'/%3E%3C/svg%3E%0A");
    position: absolute;
    height: m-toRem(14px);
    width: m-toRem(14px);
    top: 12px;
    left: 20px;
  }

  &:hover {
    &::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBmaWxsPSIjMkE0ODkxIj48cGF0aCBkPSJNNDAwIDIyNGgtMjR2LTcyQzM3NiA2OC4yIDMwNy44IDAgMjI0IDBTNzIgNjguMiA3MiAxNTJ2NzJINDhjLTI2LjUgMC00OCAyMS41LTQ4IDQ4djE5MmMwIDI2LjUgMjEuNSA0OCA0OCA0OGgzNTJjMjYuNSAwIDQ4LTIxLjUgNDgtNDhWMjcyYzAtMjYuNS0yMS41LTQ4LTQ4LTQ4ek0yNjQgMzkyYzAgMjIuMS0xNy45IDQwLTQwIDQwcy00MC0xNy45LTQwLTQwdi00OGMwLTIyLjEgMTcuOS00MCA0MC00MHM0MCAxNy45IDQwIDQwdjQ4em0zMi0xNjhIMTUydi03MmMwLTM5LjcgMzIuMy03MiA3Mi03MnM3MiAzMi4zIDcyIDcydjcyeiIvPjwvc3ZnPg==");
    }
  }
}



// blue primary btn on blue background ( gets yellow)
// ---------------------------------------------------------------------------------------------------------------------

.aa-banner-bg-blue,
.aa-u-bg--bluegradient,
.aa-u-bg--blue {

  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--yellow,
  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--primary,
  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--yellow,
  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--blue {
    color: #000a5a;
    border: none;
    background: linear-gradient(273.58deg, #F7BD68 7.09%, #F29F39 77.32%);

    &:hover {
      background: #fff;
      color: #000a5a;
    }
  }
}

// secondary btn on blue background
// ---------------------------------------------------------------------------------------------------------------------
.aa-banner-bg-blue,
.aa-u-bg--bluegradient,
.aa-u-bg--blue {

  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn--secondary,
  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--white,
  .#{$m-css-prefix}-btn.#{$m-css-prefix}-btn-outline--blue {
    color: #FFFFFF;
    border-radius: 40px;
    border: 2px solid #FFFFFF;
    background-origin: border-box;

    &:hover {
      background: #FFFFFF;
      color: #000a5a;
      border: 2px solid #234796;
    }

    &:active,
    &:focus {
      border: 2px solid #FFFFFF;
    }
  }
}

