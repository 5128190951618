
.#{$m-css-prefix}-Backdrop {
	position: absolute;
	z-index: 1;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	~ * {
		position: relative;
		z-index: 2;
		color: white;
	}
}

// Modifiers
// -----------------------------------------------------------------------------

// Cover
.#{$m-css-prefix}-Backdrop.#{$m-css-prefix}-Backdrop--cover {
	background-color: rgba(0,0,0,0.75);
}

// Left
.#{$m-css-prefix}-Backdrop.#{$m-css-prefix}-Backdrop--left {

	@include media-breakpoint-down(sm) {
		background-color: rgba(0,0,0,0.75);
	}

	@include media-breakpoint-up(md) {
		background-image: linear-gradient(to right, rgba(0,0,0,0.65) 25%, rgba(0,0,0,0) 90%);
	}

}

// Right
.#{$m-css-prefix}-Backdrop.#{$m-css-prefix}-Backdrop--right {

	@include media-breakpoint-down(sm) {
		background-color: rgba(0,0,0,0.75);
	}

	@include media-breakpoint-up(md) {
		background-image: linear-gradient(to left, rgba(0,0,0,0.65) 25%, rgba(0,0,0,0) 90%);
	}
}
