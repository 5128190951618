.#{$m-css-prefix}-Event-banner {
  margin: 2rem 0;
}

.#{$m-css-prefix}-Event-body {
  padding: m-toRem($m-f8-16) 0;

  ul {
    @include media-breakpoint-up(md) {
      column-count: 2;
    }
  }
}

.#{$m-css-prefix}-Event-footer,
.#{$m-css-prefix}-Event-panel {
  padding: m-toRem($m-f8-16) 0;
}

.#{$m-css-prefix}-Event-next {
  margin-top: m-toRem($m-f8-16);
  display: flex;
  flex-wrap: wrap;
}

.#{$m-css-prefix}-EventList-tag,
.#{$m-css-prefix}-EventList-warning,
.#{$m-css-prefix}-EventList-info,
.#{$m-css-prefix}-Event-next-box {
  color: $aa-color-blue;
  // background-color: $aa-color-gray-100;
  padding: m-toRem($m-f8-4) m-toRem($m-f8-8);
  border-radius: m-toRem($m-f8-4);
  margin-right: m-toRem($m-f8-16);
  display: table;
  font-size: .75rem;
}

.#{$m-css-prefix}-Event-next-box {
  font-size: 16px;
  line-height: 24px;
  color: #000a5a;
  padding: 0;
}

.#{$m-css-prefix}-Event-next-box-info {
  display: inline-block;
}

.#{$m-css-prefix}-Event-iframeWrapper {

  iframe {
    border: none;
    overflow: auto;
    background: transparent;
    width: 100%;
  }
}

.#{$m-css-prefix}-Event-filter {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.#{$m-css-prefix}-Event-filter-title {
  margin-bottom: .5rem
}

.#{$m-css-prefix}-Event-filter-select {
  width: 100%;
  padding: m-toRem($m-f8-40) m-toRem($m-f8-16);

  &:first-child {
    padding-left: 0;

    @include media-breakpoint-down(sm) {
      padding: m-toRem($m-f8-40) 0;
    }
  }

  &:last-child {
    padding-right: 0;

    @include media-breakpoint-down(sm) {
      padding: 0 0 m-toRem($m-f8-40) 0;
    }
  }
}
