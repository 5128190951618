.#{$m-css-prefix}-nav {
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 150;
  background-color: #fff;
  transition: 0.25s ease;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &.active {
    display: none;
  }

  &.animate {
    padding: 15px;
    box-shadow: 0px 5px 10px rgba(9, 26, 66, 0.1);

    .#{$m-css-prefix}-nav{
      &-links-level0.active::before {
        top: 48px;
      }

      &-submenu {
        top: 54px;
      }

      &-logo img {
        top: -5px;
      }
    }
  }

  &-inner {
    display: flex;
    align-items: center;
  }

  &-links {
    color: #000a5a;
    font-weight: 600;

    @include media-breakpoint-up(lg) {
      display: inline-flex;
      flex: 2;
      border: none;
      box-shadow: none;
    }

    &-level0 {
      position: relative;
      align-self: center;

      >a {
        padding-bottom: 60px;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 56px;

        &:hover .#{$m-css-prefix}-nav-submenu {
          display: block;
        }
      }

      &::before {
        content: '';
        border-radius: 8px;
        height: 6px;
        width: 100%;
        position: absolute;
        top: 64px;
        z-index: 990;
        background: transparent;
        transition: 0.25s ease;

        &:hover .#{$m-css-prefix}-nav-submenu {
          display: block;
        }
      }

      @include media-breakpoint-up(lg) {
        &.active::before {
          background: #234796;
        }
      }
    }

    a {
      color: #000a5a;
      text-decoration: none;

      &:visited {
        color: #000a5a;
      }
    }
  }

  &-logo {
    display: inline-flex;
    flex: 1;
    justify-content: center;
    position: relative;
    top: -5px;

    img {
      max-width: 200px;
      height: auto;
      max-height: 65px;
      width: auto;
      position: relative;
      top: 0;
      transition: top 0.25s ease;
    }

    & > * {
      max-height: 45px;
    }
  }

  &-search {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    position: relative;

    input,
    button {
      background-color: transparent;
      border: none;
      color: #000a5a;
    }

    button {
      padding: 12px 4px;
      outline: none;
    }

    input {
      border-bottom: 1px solid #000a5a;
      outline: none;
      position: absolute;
      padding: 10px 30px 10px 0;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 0;
        -webkit-transition: opacity .4s, width 0.4s ease-in-out;
        transition: opacity .4s, width 0.4s ease-in-out;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
      }
    }

    &:focus-within {
      input {
        opacity: 1;
        z-index: -1;

        @include media-breakpoint-up(lg) {
          width: 90%;
        }
      }

      button {
        z-index: 1;
      }
    }
  }

  &-submenu {
    display: none;
    background-color: #ffffff;

    @include media-breakpoint-up(lg) {
      border: 2px solid #F1F5FF;
      box-shadow: 0px 0px 15px rgba(9, 26, 66, 0.1);
      padding: 23px;
      position: absolute;
      left: 0;
      top: 70px;
      width: auto;
      z-index: 1001;
    }

    @include media-breakpoint-down(lg) {
      &.is-open {
        display: block;
      }

      &-arrow.is-open::after {
        transform: rotate(-90deg);
      }
    }

    &-arrow::after {
      content: '';
      background: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.40625 7.76685C8.5625 7.64185 8.65625 7.45435 8.65625 7.2356C8.65625 7.0481 8.5625 6.8606 8.40625 6.70435L2.34375 0.641846C2.1875 0.516846 2 0.423096 1.8125 0.423096C1.59375 0.423096 1.40625 0.516846 1.28125 0.641846L0.59375 1.32935C0.4375 1.4856 0.34375 1.6731 0.34375 1.8606C0.34375 2.07935 0.40625 2.26685 0.5625 2.39185L5.40625 7.2356L0.5625 12.0793C0.40625 12.2356 0.34375 12.4231 0.34375 12.6106C0.34375 12.8293 0.4375 13.0168 0.59375 13.1418L1.28125 13.8293C1.40625 13.9856 1.59375 14.0481 1.8125 14.0481C2 14.0481 2.1875 13.9856 2.34375 13.8293L8.40625 7.76685Z' fill='%23F29F39'/%3E%3C/svg%3E") no-repeat;
      transform: rotate(90deg);
      position: absolute;
      right: 16px;
      top: 25px;
      width: 10px;
      height: 15px;
      transition: transform .7s ease;
    }

    &-link {
      font-weight: 500;
      font-size: 18px;
      padding: 10px;
      white-space: nowrap;

      &.active a {
        color: #F29F39;
      }
    }
  }

  &-link-static {
    @include media-breakpoint-up(lg) {
      border-top: 1px solid rgba(9, 26, 66, 0.4);
      margin-top: 10px;
      padding-top: 20px;
    }

    a {
      display: block;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &::after {
        content: '';
        background: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.40625 7.76685C8.5625 7.64185 8.65625 7.45435 8.65625 7.2356C8.65625 7.0481 8.5625 6.8606 8.40625 6.70435L2.34375 0.641846C2.1875 0.516846 2 0.423096 1.8125 0.423096C1.59375 0.423096 1.40625 0.516846 1.28125 0.641846L0.59375 1.32935C0.4375 1.4856 0.34375 1.6731 0.34375 1.8606C0.34375 2.07935 0.40625 2.26685 0.5625 2.39185L5.40625 7.2356L0.5625 12.0793C0.40625 12.2356 0.34375 12.4231 0.34375 12.6106C0.34375 12.8293 0.4375 13.0168 0.59375 13.1418L1.28125 13.8293C1.40625 13.9856 1.59375 14.0481 1.8125 14.0481C2 14.0481 2.1875 13.9856 2.34375 13.8293L8.40625 7.76685Z' fill='%23F29F39'/%3E%3C/svg%3E") no-repeat;
        width: 10px;
        height: 14px;
        position: absolute;
        margin-top: 5px;
        margin-left: 15px;
      }
    }
  }

  &-mobile {
    display: flex;
    justify-content: space-between;

    padding: 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 150;
    background-color: #fff;
    transition: 0.25s ease;
    max-width: 100vw;

    &-logo {

      img {
        max-width: 200px;
      }

      &>* {
        display: block;
        height: 33px;
        width: auto;

        >* {
          height: inherit;
          width: auto;
        }
      }
    }

    &-menu {
      position: relative;
      top: 7px;

      .#{$m-css-prefix}-nav-toggle-bar {
        transition: transform 0.7s ease;
        opacity: 1;
        height: 8px;
        position: relative;
        top: 0;
        right: 16px;

        svg {
          position: absolute;
          right: -23px;
          top: 2px;
        }
      }

      &.is-open {
        .#{$m-css-prefix}-nav-toggle-bar {
          &:first-child {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
            transition: background-color 0.7s linear, transform 0.7s ease, opacity 0.3s ease;
          }

          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &-close {
      display: none;
    }

    &-border {
      border-bottom: 1px solid #CED1D9;
    }
  }

  &-menu-open {
    background: #fff;
    transition: left .7s ease;
    // transform: scaleY(0);
    position: fixed;
    bottom: 0;
    top: 65px;
    left: 100%;
    z-index: 1002;
    overflow: auto;
    width: 100%;
    max-width: 100vw;

    &.is-open {
      // transform: scaleY(1);
      left: 0;
    }
  }
}

@keyframes search {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
