
.sb-TextPic {}

// Modifiers
// -----------------------------------------------------------------------------
.sb-TextPic.sb-TextPic--iconAboveCenter {

	img {
		display: block;
		margin: 0 auto m-toRem($m-f8-40);
	}
}

.sb-TextPic.sb-TextPic--iconBesideTextLeft {

	@include media-breakpoint-up(md) {
		display: flex;
		align-items: flex-start;
	}

	img {
		float: left;
		margin-right: m-toRem($m-f8-24);
		margin-bottom: m-toRem($m-f8-24);

		@include media-breakpoint-up(md) {
			float: none;
			flex: 0 0 auto;
			margin-right: m-toRem($m-f8-40);
			margin-bottom: 0;
		}
	}
}
