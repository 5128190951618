
.t3m-Wall {
  iframe {
    width: 100%;
  }
}


.t3m-Wall-wrapper {
  padding: $card-spacer-x;
  position: relative;
  background: transparent;
}

.t3m-Wall-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $aa-color-gray-200;
  background-size: cover;
  background-position: center;
  z-index: -1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $aa-color-rgba-white-5;
  }
}

.t3m-Wall-header{}

.t3m-Wall-logo {
  max-width: 10rem;
}

.t3m-Wall-title{
  margin: 1rem 0;
}


.t3m-Wall-body {
  display: flex;
  margin-bottom: m-toRem($m-f8-16);
  flex-direction: column-reverse;

  p:last-child{
    margin-bottom: 0;
  }


  a:visited,

  a {
    color: $aa-color-blue;

    @include hover-focus-active{
      color: $aa-color-yellow;
    }
  }

  @media only screen and (min-width: 576px) {
    flex-direction: row;

    > *:first-child {
      hyphens: auto;
      width:100%;
    }

    > * {
      width: 66%;
    }
  }


}

* {
  box-sizing: border-box;
}

/** Switch
 -------------------------------------*/

.t3m-Wall-switch {

  margin-left: 1rem;


}


.t3m-Wall-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  p {
    margin-bottom: 0;
    margin-right: .5rem;
  }

  .custom-switch {
    padding-bottom: 2rem;
  }
}


