.#{$m-css-prefix}-Acdordion {
}

.#{$m-css-prefix}-Accordion-content {
  background-color: white;
  position: relative;
  margin-bottom: m-toRem($m-f8-16);
  border: 1px solid $aa-color-gray-300;
  border-bottom: none;
}


a.#{$m-css-prefix}-Accordion-btn:visited,
a.#{$m-css-prefix}-Accordion-btn {
  width: 100%;
  padding: .75rem 3rem .5rem 1rem;
  position: relative;
  color: $aa-color-blue;
  text-decoration: none;
  display: block;
  font-weight: 500;
  border-color: transparent;
  transition: border-bottom-color .2s linear .2s;

  svg {
    transform: scaleY(-1);
    position: absolute;
    right: m-toRem($m-f8-8);
    width: m-toRem($m-f8-16);
    height: m-toRem($m-f8-16);
    top: calc(50% - .5rem);
    fill: $aa-color-gray-900;
    transition: $transition-base;
  }


  &.collapsed {
    border-bottom: 2px solid $aa-color-orange;

    svg {
      transform: scaleY(1);
    }


    &:hover {
      border-color: $aa-color-blue;

      &:after {
        color: $aa-color-blue;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.#{$m-css-prefix}-Accordion-body {
  padding: m-toRem($m-f8-16);
  border-bottom: 2px solid $aa-color-blue;


  .collapsing,
  .show {
    border-color: $aa-color-blue;
  }

  .ce-bodytext p {
    color: $aa-color-gray-700;
  }


}
