.#{$m-css-prefix}-SpeakerList {
  margin-top: 34px;

  .#{$m-css-prefix}-Panel {
    margin-bottom: 40px;
    background-color: #fff;
  }
}

.#{$m-css-prefix}-Panel {
  border-bottom: 1px solid rgba(9, 26, 66, 0.15);
  padding-bottom: 40px;

  &:first-child {
    padding-top: 40px;
    border-top: 1px solid rgba(9, 26, 66, 0.15);
  }

  &-image {
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    img {
      object-fit: cover;
      border-radius: 50%;
      height: 164px;
      width: 164px;
    }

  }


  &-title {
    font-size: 18px;
    line-height: 24px;
    color: #000a5a;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(9, 26, 66, 0.6);

    p {
      margin: 0;
    }
  }

}
