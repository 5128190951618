
html, body {
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  //padding-top: $aa-header-height-desktop;

  // @include media-breakpoint-down(md) {
  //   padding-top: $aa-header-height-mobile;
  // }


  letter-spacing: .5px;
}


// Exclude the RTE since we're including the frontend CSS for the backend at the moment
body:not(.cke_editable) {

  html.is-locked & {
    overflow: hidden;
  }
}

.#{$m-css-prefix}-Title.#{$m-css-prefix}-Title--withBars {
  position: relative;
  // padding-left: 2.5rem;
}

figure {
  margin: 0;
}

.img-fluid {
  width: 100%;
}
