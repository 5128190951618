@use 'sass:math';

// Positioning
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-u-allign-end {
  align-items: flex-end !important;
  align-self: flex-end !important;
  align-content: flex-end !important;
}

// RTE Additonal Styles
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-u-col-2 {
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}

.list-style {
  &-1 {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMyAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjk2MjMgMS4yODUyNUwyMS45NjI0IDEuMjg1MzJDMjIuNDg2NCAxLjgwMTkxIDIyLjQ4NTUgMi42NDA0NSAyMS45NTk4IDMuMTU1NzhDMjEuOTU5OCAzLjE1NTgxIDIxLjk1OTggMy4xNTU4MyAyMS45NTk3IDMuMTU1ODVMOC4xMDAwNCAxNi43NTA4TDguMDk5OTUgMTYuNzUwOUM3Ljg0ODg2IDE2Ljk5NjkgNy41MDkwNiAxNy4xMzQ1IDcuMTU2NTQgMTcuMTM0NUwyMS45NjIzIDEuMjg1MjVaTTIxLjk2MjMgMS4yODUyNUMyMS40NDA1IDAuNzcxMTEgMjAuNTk1MSAwLjc2OTkxNiAyMC4wNzI4IDEuMjgzMDlMMjEuOTYyMyAxLjI4NTI1Wk02LjE3MzI4IDE2LjcxQzYuNDE5OTEgMTYuOTczNiA2Ljc2MzkgMTcuMTI2IDcuMTI1NzcgMTcuMTM0MUwyMC4wNzI2IDEuMjgzMjVMNy4xOTc1NSAxMy45MTIxTDIuNjYxMTkgOS4wNjY1OUwyLjY2MTE0IDkuMDY2NTRDMi4xNjA1OSA4LjUzMjE4IDEuMzE2NTEgOC40OTgwMSAwLjc3Mjk5MiA4Ljk4OTU3TDAuNzcyOTA5IDguOTg5NjRDMC4yMjcyMyA5LjQ4MzU5IDAuMTkxOTI4IDEwLjMyMTIgMC42OTQ2MzcgMTAuODU4NEwwLjY5NDY3MiAxMC44NTg0TDYuMTczMjIgMTYuNzA5OUM2LjE3MzI0IDE2LjcxIDYuMTczMjYgMTYuNzEgNi4xNzMyOCAxNi43MVoiIGZpbGw9IiNGMjlGMzkiIHN0cm9rZT0iI0YyOUYzOSIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPC9zdmc+Cg==);
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 15px;
        display: block;
        margin-right: 18px;
      }
    }
  }
}

// Background colors
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-u-bg--white.#{$m-css-prefix}Card,
.#{$m-css-prefix}-u-bg--white {
  background-color: white;
  color: $aa-color-gray-700;

  p {
    color: $aa-color-gray-700;
  }

  .#{$m-css-prefix}-Title,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000a5a;
  }

  svg {
    fill: #000a5a;
  }
}

.#{$m-css-prefix}-u-bg--blue.#{$m-css-prefix}-Card:before {
  content: '';
  border-bottom: m-toRem($m-f8-4) solid rgba(0, 0, 0, .2);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.#{$m-css-prefix}-u-bg--blue.#{$m-css-prefix}-Card,
.#{$m-css-prefix}-u-bg--blue {
  position: relative;
  background: $aa-color-blue-gradient;
  color: white;

  .#{$m-css-prefix}-Title,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: white;
  }

  svg {
    fill: white;
  }

  &.#{$m-css-prefix}-Card--link:hover {
    box-shadow: $aa-box-shadow-blue;
  }

  .#{$m-css-prefix}-Title--withMobileUnderline {
    border-color: $aa-color-rgba-white-3;
  }

  a:not(.#{$m-css-prefix}-btn) {
    color: white;
    text-decoration: none;

    &:visited {
      color: white;
    }

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  hr {
    color: white;
  }
}

.#{$m-css-prefix}-u-bg--bluegradient.#{$m-css-prefix}Card,
.#{$m-css-prefix}-u-bg--bluegradient {
  background: linear-gradient(316.74deg, #234796 19.09%, #000a5a 72.24%);
  color: white;

  .#{$m-css-prefix}-Title,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: white;
  }

  svg {
    fill: white;
  }
}

.#{$m-css-prefix}-u-bg--lightblue.#{$m-css-prefix}Card,
.#{$m-css-prefix}-u-bg--lightblue {
  background-color: #F1F5FF;

  .#{$m-css-prefix}Title,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000a5a;
  }

  p {
    color: #000a5a;
  }

  svg {
    fill: $aa-color-blue;
  }
}

.#{$m-css-prefix}-u-bg--lightGray.#{$m-css-prefix}Card,
.#{$m-css-prefix}-u-bg--lightGray {
  background-color: $aa-color-gray-200;

  .#{$m-css-prefix}Title,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000a5a;
  }

  p {
    color: $aa-color-gray-700;
  }

  svg {
    color: #000a5a;
  }
}

.#{$m-css-prefix}-u-bg--lightGrayWithBorder.#{$m-css-prefix}Card,
.#{$m-css-prefix}-u-bg--lightGrayWithBorder {
  background-color: $aa-color-gray-200;
  border: m-toRem($m-f8-8) solid white;

  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
  }

  .#{$m-css-prefix}-Title,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000a5a;
  }

  p {
    // color: $aa-color-gray-700;
  }

  svg {
    fill: #000a5a;
  }

  &.#{$m-css-prefix}-Section {
    box-shadow: 0 0 24px $aa-color-rgba-blue-2;
  }

  .container {
    padding-left: calc(#{$grid-gutter-width} - #{m-toRem($m-f8-8)});
    padding-right: calc(#{$grid-gutter-width} - #{m-toRem($m-f8-8)});

  }
}

.#{$m-css-prefix}-u-bg--transparent.#{$m-css-prefix}Card,
.#{$m-css-prefix}-u-bg--transparent {
  background-color: transparent;
  color: inherit;
  box-shadow: none;

  >* {
    padding-left: 0;
    padding-right: 0;
  }

  .#{$m-css-prefix}-Card-icon-wrapper {
    padding-top: 0;
  }

  .card-title,
  .#{$m-css-prefix}-Title,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {

    padding-left: 0;
    padding-right: 0;
  }

  svg {
    fill: currentColor;
    color: inherit;
  }

  &.#{$m-css-prefix}-Card--link:hover {
    box-shadow: none;
  }

}


.#{$m-css-prefix}-Section.#{$m-css-prefix}-Section--default {

  >.#{$m-css-prefix}-u-p-box {

    @each $breakpoint in map-keys($vertical-grid-gutter-widths) {
      @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($vertical-grid-gutter-widths, $breakpoint);
        padding: math.div($gutter, 1.6);
      }
    }

    .container {
      padding: 0;
    }
  }
}



// Flexperto-Banner
//----------------------------------------------------------------------------------------------------------------------
.#{$m-css-prefix}-FlexpertoBanner {
  min-height: 0 !important;
  background-position: right !important;
  background-size: contain !important;
}

.#{$m-css-prefix}-orange {
  color: #F29F39;
}

//  SparrowSyrvey Form
//---------------------------------------------------------------------------------------
a.surveySparrowFormLink {
  cursor: pointer;
}
