@font-face {
  font-family: "Helvetica Neue LT";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("#{$m-fontFolder-path}/5760841n/46dd84ea-bb96-46da-973b-d7fcca46437e.woff2") format("woff2"),
  url("#{$m-fontFolder-path}/5760841n/0fb90058-c064-40ed-8109-d1fd8633caa2.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Neue LT";
  font-display: swap;
  font-style: normal; 
  font-weight: 600;
  src: url("#{$m-fontFolder-path}/5760861n/39ae88e8-7d53-4395-8f9d-f7a0d4060ec4.woff2") format("woff2"),
  url("#{$m-fontFolder-path}/5760861n/0c4f3bb0-d605-4462-9603-9163a560a536.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Neue LT";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("#{$m-fontFolder-path}/5760899n/13ca6b78-8b50-4c1b-ab2b-f5f977744644.woff2") format("woff2"),
  url("#{$m-fontFolder-path}/5760899n/07a54a3f-7d5e-4b4b-a8de-51ea987d4c2f.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Neue LT";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("#{$m-fontFolder-path}/5761058n/e62ce8f4-9983-4262-9030-3fdbebcebf39.woff2") format("woff2"),
  url("#{$m-fontFolder-path}/5761058n/0e8ae071-ceee-4b41-9d37-cbb6af5a9ca9.woff") format("woff");
}



// @font-face {
//   // font-family: "Helvetica Neue LT W05 45 Light";
//   font-family: "Helvetica Neue LT";
//   font-display: swap;
//   font-style: normal;
//   font-weight: 400;
//   src: url("#{$m-fontFolder-path}/5664085/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix");
//   src: url("#{$m-fontFolder-path}/5664085/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix") format("eot"), url("#{$m-fontFolder-path}/5664085/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"), url("#{$m-fontFolder-path}/5664085/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff"), url("#{$m-fontFolder-path}/5664085/fdf10d5e-4ce0-45e5-a6c1-5eaff6cb1c11.ttf") format("truetype");
// }

// @font-face {
//   // font-family: "Helvetica Neue LT W05 55 Roman";
//   font-family: "Helvetica Neue LT";
//   font-display: swap;
//   font-style: normal;
//   font-weight: 500;
//   src: url("#{$m-fontFolder-path}/5664093/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix");
//   src: url("#{$m-fontFolder-path}/5664093/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("eot"), url("#{$m-fontFolder-path}/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("#{$m-fontFolder-path}/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"), url("#{$m-fontFolder-path}/5664093/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
// }

// @font-face {
//   // font-family: "Helvetica Neue LT W05 65 Medium";
//   font-family: "Helvetica Neue LT";
//   font-display: swap;
//   font-style: normal;
//   font-weight: 600;
//   src: url("#{$m-fontFolder-path}/5664103/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix");
//   src: url("#{$m-fontFolder-path}/5664103/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("eot"), url("#{$m-fontFolder-path}/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("#{$m-fontFolder-path}/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"), url("#{$m-fontFolder-path}/5664103/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
// }

// @font-face {
//   // font-family: "Helvetica Neue LT W05_75 Bold";
//   font-family: "Helvetica Neue LT";
//   font-display: swap;
//   font-style: normal;
//   font-weight: 700;
//   src: url("#{$m-fontFolder-path}/5664150/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix");
//   src: url("#{$m-fontFolder-path}/5664150/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("eot"), url("#{$m-fontFolder-path}/5664150/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("#{$m-fontFolder-path}/5664150/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"), url("#{$m-fontFolder-path}/5664150/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
// }

// // Black
// @font-face {
//   font-family: 'Helvetica Neue LT Pro';
//   font-display: swap;
//   font-style: normal;
//   font-weight: 900;
//   src: url('#{$m-fontFolder-path}/HelveticaNeueLTPro-Blk.woff') format('woff');
// }
