// Responsive font size
// -----------------------------------------------------------------------------
html {
  font-size: $m-font-size-root;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 18px;
  }

  @include media-breakpoint-up(xxxl) {
    font-size: 18px;
  }
}

p {
  // font-family: $font-family-base;
}

// Font weights
// -----------------------------------------------------------------------------
b,
strong,
th {
  font-weight: 600;
}


// Headlines
// -----------------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: 0;
  font-weight: 600;
  color: #000a5a;
}

h1,
.h1 {
  @include m-makeH(1);
}

h2,
.h2 {
  @include m-makeH(2);
}

h3,
.h3 {
  @include m-makeH(3);
}

h4,
.h4 {
  @include m-makeH(4);
}

h5,
.h5 {
  @include m-makeH(5);
}

h6,
.h6 {
  @include m-makeH(6);
}


// Links in headlines
// -----------------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  a {
    font-family: inherit;
    color: inherit;

    @include hover-focus {
      text-decoration: inherit;
      color: $link-hover-color;
    }
  }
}


// Bold or strong in headlines
// -----------------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  b,
  strong {
    border-bottom: 1px solid;
  }
}


// Headlines custom
// -----------------------------------------------------------------------------
.h2-small {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 18px;
}

// Line breaks in headlines
// -----------------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  br {

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}


// Additional Links stylings
// -----------------------------------------------------------------------------


a {

  text-decoration: underline;

}

// Pre-Headline
// -----------------------------------------------------------------------------
.m-u-preheadline {
  font-family: $font-family-base;
  text-transform: uppercase;
  letter-spacing: 0.4em;
  font-size: $font-size-sm;
}


// Display Headlines
// -----------------------------------------------------------------------------
.display-1 {
  letter-spacing: 0.02em;

  @include media-breakpoint-down(xs) {
    font-size: $h1-font-size;
    line-height: $headings-line-height;

    letter-spacing: 0;
  }
}


// Add a little extra margin to headlines succeeding paragraphs
// -----------------------------------------------------------------------------
p:not(.badge),
ul,
ol {

  +h1,
  +h2,
  +h3,
  +h4,
  +h5,
  +h6,
  +.h1,
  +.h2,
  +.h3,
  +.h4,
  +.h5,
  +.h6 {
    margin-top: 2 * $headings-margin-bottom;
  }
}


// Horizontal rules
// -----------------------------------------------------------------------------
hr {
  margin-top: .5rem;
  margin-bottom: .5rem;
  border: 0;
  opacity: 0.5;
  border-top: $hr-border-width solid $hr-border-color;
}


hr.#{$m-css-prefix}-u-border {

  &.#{$m-css-prefix}-u-border--notMobile {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

// Links
// -----------------------------------------------------------------------------
// a {}


// Emphasis
// -----------------------------------------------------------------------------
// small,
// .small {}

// Blockquotes
// -----------------------------------------------------------------------------

blockquote {
  font-family: $font-family-serif;
  font-style: italic;
  font-size: $font-size-lg;
  line-height: 1.7;
  padding: m-toRem($m-f8-24) 0;
  margin-bottom: 0;

  &:before {
    content: '“';
    font-size: 1.5em;
    padding-right: m-toRem($m-f8-8);
    line-height: 1;
    transform: translate(-100%) scale(1.6);
    display: inline-block;
    position: absolute;
    transform-origin: right 25%;
  }

  p:last-child {
    font-family: $font-family-sans-serif;
    font-size: $font-size-sm;
    line-height: $line-height-base;
    border-top: 1px solid rgba($body-color, 0.5);
    padding-top: m-toRem($m-f8-16);
  }
}


// Margin within headlines
// ---------------------------------------------------------------------------------------------------------------------
//header > *:first-child {
//  margin-top: 0;
//}

// Headlines Stylez
// ---------------------------------------------------------------------------------------------------------------------
.#{$m-css-prefix}-Title {
  color: #000a5a;
  font-weight: 600;
  font-size: m-toRem(22px);
  line-height: m-toRem(30px);
  // text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    font-size: m-toRem(18px);
    line-height: m-toRem(30px);
  }


  @each $breakpoint in map-keys($headeline-margin) {
    @include media-breakpoint-up($breakpoint) {
      $margin: map-get($headeline-margin, $breakpoint);
      margin-top: $margin;
      margin-bottom: $margin;

    }
  }
}

h1 {
  &.#{$m-css-prefix}-Title {
    font-size: 48px;
    line-height: 56px;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

h2 {
  &.#{$m-css-prefix}-Title {
    font-size: 40px;
    line-height: 48px;

    @include media-breakpoint-down(sm) {
      font-size: 26px;
      line-height: 34px;
    }
  }
}

h3 {
  &.#{$m-css-prefix}-Title {
    font-size: 32px;
    line-height: 40px;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
      line-height: 30px;
    }
  }
}

h4 {
  &.#{$m-css-prefix}-Title {
    font-size: 24px;
    line-height: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

h5,
h6 {
  &.#{$m-css-prefix}-Title {
    font-size: m-toRem(22px);

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}



.#{$m-css-prefix}-SubTitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #234796;
}

.#{$m-css-prefix}-SubTitle {

  @each $breakpoint in map-keys($headeline-margin) {
    @include media-breakpoint-up($breakpoint) {
      $margin: map-get($headeline-margin, $breakpoint);
      margin-top: $margin;
      margin-bottom: $margin;

    }
  }
}


.#{$m-css-prefix}-Title.#{$m-css-prefix}-Title--withBars {
  position: relative;
  // padding-left: 2.5rem;

  // &:before {
  //   content: '';
  //   width: 2rem;
  //   height: 2rem;
  //   display: block;
  //   z-index: 1000;
  //   background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 27 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23de9d00' stroke-width='2'%3E%3Cpath d='m8.162 1.025 10.483 29.884h-1.039l-10.269-29.884h.825z'/%3E%3Cpath d='m14.095 1.067 10.482 29.885h-1.039l-10.268-29.885z'/%3E%3Cpath d='m2.195 1.005 10.483 29.885h-1.039l-10.269-29.885z'/%3E%3C/g%3E%3C/svg%3E");
  //   background-repeat: no-repeat;
  //   background-position: 50% 50%;
  //   position: absolute;
  //   left: 0;

  //   @include media-breakpoint-down(sm) {
  //     top: m-toRem($m-f8-4*-2);
  //   }
  // }
}


h4.#{$m-css-prefix}-Title.#{$m-css-prefix}-Title--withBars {
  // padding-left: 2rem;

  // &:before {
  //   width: 1.75rem;
  //   height: 1.75rem;
  // }
}



.#{$m-css-prefix}-Title.#{$m-css-prefix}-Title--withMobileUnderline {

  // padding-bottom: 1rem;
  // border: 0;
  // border-bottom: $hr-border-width solid $hr-border-color;

}

.display-1,
.display-2,
.display-3,
.display-4 {
  &.#{$m-css-prefix}-SubTitle {
    font-weight: 500;
  }
}
