.video {
  width: 100%;
  height: auto;

  &-embed {
    position: relative;
    &-yt {
      width: 100%;
    }

    &-item {
      &-yt {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }

      &-full-width {
        width: 100%;
      }

      &-close {
        +.fancybox-close-small {
          display: none;
        }
      }
    }
  }
}
.#{$m-css-prefix}-Slide-video-container.#{$m-css-prefix}-btn-play {

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='81' height='81' viewBox='0 0 81 81' fill='none'%3E%3Ccircle cx='40.3867' cy='40.6205' r='39' fill='url(%23paint0_linear_1145_10437)' stroke='url(%23paint1_linear_1145_10437)' stroke-width='2'/%3E%3Cpath d='M58.7197 40.9795L28.7197 19.3707L28.7197 60.6207L58.7197 40.9795Z' fill='white' stroke='url(%23paint2_linear_1145_10437)' stroke-width='2' stroke-linecap='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1145_10437' x1='48.8758' y1='80.6205' x2='7.69566' y2='36.8548' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%232A4891'/%3E%3Cstop offset='1' stop-color='%23032578'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_1145_10437' x1='48.8758' y1='80.6205' x2='7.69566' y2='36.8548' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%232A4891'/%3E%3Cstop offset='1' stop-color='%23032578'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint2_linear_1145_10437' x1='58.7197' y1='35.6185' x2='37.6458' y2='50.0396' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%232A4891'/%3E%3Cstop offset='1' stop-color='%23032578'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    pointer-events: none;
  }
  video::-webkit-media-controls-panel {
    display: none;
  }
  video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }
  video::-webkit-media-controls-play-button {
    display: none !important;
  }
}
