.fancybox-container {
  .fancybox-toolbar {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.fancybox-slide--iframe, .fancybox-slide--html {
  padding: 0;

  .fancybox-content {
    padding-left: 10vw;
    padding-right: 10vw;
    margin: 0;

    @include media-breakpoint-up(md) {
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
    }
  }
}

.fancybox-slide {
  text-align: left;
  .ss-survey-launch__close-button {
    display: none;
  }
}
