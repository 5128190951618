/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


 // Bootstrap
 // -----------------------------------------------------------------------------
 @import "../vendor/bootstrap-4.3.1/functions";


// Custom
// -----------------------------------------------------------------------------
@import "../mediatis/mixins/m-functions";
@import "../mediatis/variables/m-variables";
@import "aa-variables";


// Bootstrap
// -----------------------------------------------------------------------------
@import "../vendor/bootstrap-4.3.1/variables";
@import "../vendor/bootstrap-4.3.1/mixins";

// Override some Bootstrap mixins!!!
// @import "../mediatis/mixins/m-bootstrap-overrides";

@import "../vendor/bootstrap-4.3.1/root";
@import "../vendor/bootstrap-4.3.1/reboot";
@import "../vendor/bootstrap-4.3.1/type";
@import "../vendor/bootstrap-4.3.1/images";
@import "../vendor/bootstrap-4.3.1/code";
@import "../vendor/bootstrap-4.3.1/grid";
@import "../vendor/bootstrap-4.3.1/tables";
@import "../vendor/bootstrap-4.3.1/forms";
@import "../vendor/bootstrap-4.3.1/buttons";
@import "../vendor/bootstrap-4.3.1/transitions";
@import "../vendor/bootstrap-4.3.1/dropdown";
@import "../vendor/bootstrap-4.3.1/button-group";
@import "../vendor/bootstrap-4.3.1/input-group";
@import "../vendor/bootstrap-4.3.1/custom-forms";
@import "../vendor/bootstrap-4.3.1/nav";
@import "../vendor/bootstrap-4.3.1/navbar";
@import "../vendor/bootstrap-4.3.1/card";
@import "../vendor/bootstrap-4.3.1/breadcrumb";
@import "../vendor/bootstrap-4.3.1/pagination";
@import "../vendor/bootstrap-4.3.1/badge";
@import "../vendor/bootstrap-4.3.1/jumbotron";
@import "../vendor/bootstrap-4.3.1/alert";
@import "../vendor/bootstrap-4.3.1/progress";
@import "../vendor/bootstrap-4.3.1/media";
@import "../vendor/bootstrap-4.3.1/list-group";
@import "../vendor/bootstrap-4.3.1/close";
@import "../vendor/bootstrap-4.3.1/toasts";
@import "../vendor/bootstrap-4.3.1/modal";
@import "../vendor/bootstrap-4.3.1/tooltip";
@import "../vendor/bootstrap-4.3.1/popover";
@import "../vendor/bootstrap-4.3.1/carousel";
@import "../vendor/bootstrap-4.3.1/spinners";
@import "../vendor/bootstrap-4.3.1/utilities";
@import "../vendor/bootstrap-4.3.1/print";



// Third party
// -----------------------------------------------------------------------------


// Animate.css
// @import "../vendor/animate-3.7.2/animate.scss";

// Font Awesome
// @import "../vendor/fontawesome-pro-5.9.0/fontawesome.scss";
// @import "../vendor/fontawesome-pro-5.9.0/brands.scss";
// @import "../vendor/fontawesome-pro-5.9.0/light.scss";
// @import "../vendor/fontawesome-pro-5.9.0/regular.scss";
// @import "../vendor/fontawesome-pro-5.9.0/solid.scss";

// Fancybox
@import "../vendor/fancybox-3.5.2/fancybox";

// noUiSlider
// @import "../vendor/nouislider-11.1.0/nouislider-11.1.0.scss"; // Kept this one in the repository. Not sure if new version causes bugs.
// @import "../vendor/nouislider-11.1.0/nouislider-14.1.0.scss";

// Slick
@import "../vendor/slick-1.9.0/slick.scss";
@import "../vendor/slick-1.9.0/slick-theme.scss";


// Mediatis CSS Helpers
// Might make use of third party scss
// -----------------------------------------------------------------------------

// @import "../mediatis/fonts/m-font-montserrat";
// @import "../mediatis/fonts/m-font-open-sans";

// @import "../mediatis/mixins/m-buttons";
// @import "../mediatis/mixins/m-decoration";
@import "../mediatis/mixins/m-type";
@import "../mediatis/mixins/m-type";

@import "../mediatis/typo3/m-ce-gallery";
// @import "../mediatis/typo3/m-debug";
@import "../mediatis/typo3/m-fluid-styled-content";

// @import "../mediatis/utilities/m-animate";
// @import "../mediatis/utilities/m-background";
@import "../mediatis/utilities/m-breakpoint-detector";
// @import "../mediatis/utilities/m-buttons"; // avoid this
// @import "../mediatis/utilities/m-form";
// @import "../mediatis/utilities/m-grid";
// @import "../mediatis/utilities/m-hamburger";
// @import "../mediatis/utilities/m-icons";
// @import "../mediatis/utilities/m-icons-variant";
// @import "../mediatis/utilities/m-images";
// @import "../mediatis/utilities/m-type";


// Projects' Mixins files
// -----------------------------------------------------------------------------
// @import "mixins/aa-buttons";

// Projects' SCSS files
// -----------------------------------------------------------------------------
@import "plugin/t3m-wall";
@import "plugin/aa-bootstrap-select";
@import "plugin/aa-fancybox";
@import "plugin/aa-event";
@import "plugin/aa-panel";
@import "plugin/aa-eventList";
@import "plugin/aa-recaptcha";
@import "plugin/aa-survey";
@import "aa-accordion";
@import "aa-backdrop";
@import "aa-buttons";
@import "aa-card";
@import "aa-carousel";
@import "aa-checkbox";
@import "aa-collapsible";
@import "aa-container";
@import "aa-contact-sticky";
@import "aa-cookiebot";
@import "aa-decision-hub";
@import "aa-feature";
@import "aa-feature";
@import "aa-featurgrid";
@import "aa-fonts";
@import "aa-footer";
@import "aa-form";
@import "aa-header";
@import "aa-icon";
@import "aa-info";
@import "aa-leistungen";
@import "aa-list";
@import "aa-logo";
@import "aa-navigation";
@import "aa-navigation-toggler";
@import "aa-nav";
@import "aa-protected";
@import "aa-events";
@import "aa-news";
@import "aa-patient";
@import "aa-radiobuttons";
@import "aa-row";
@import "aa-scaffolding";
@import "aa-search";
@import "aa-section";
@import "aa-sitewrapper";
@import "aa-slider";
@import "aa-special";
@import "aa-subpagemenu";
@import "aa-table";
@import "aa-typo3-frames";
@import "aa-typo3-image";
@import "aa-typo3-textpic";
@import "aa-type";
@import "aa-utillitys";
@import "aa-teaserBig";
@import "aa-partnergrid";
@import "aa-banner";
@import "aa-numbers";
@import "aa-tabs";
@import "aa-login";
@import "aa-pagination";
@import "aa-video";
@import "aa-surveysparrow";
@import "aa-jubilee-sticky";
@import "aa-popup";
@import "aa-newsteaser";
