.#{$m-css-prefix}-News {
}

.#{$m-css-prefix}-News.#{$m-css-prefix}-News--table {
  position: relative;
  width: 100%;
}

.#{$m-css-prefix}-News-linkWrap:visited,
.#{$m-css-prefix}-News-linkWrap {
  display: flex;
  text-decoration: none;
  margin-bottom: .125rem;
  padding: .5rem;
  background-color: $aa-color-gray-200;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    color: $aa-color-blue;

    .#{$m-css-prefix}-News-categorie,
    .#{$m-css-prefix}-News-text {
      color: $aa-color-blue;
    }
  }
}

.#{$m-css-prefix}-News-categorie {
  min-width: 12rem;
  margin-right: .5rem;
  color: $aa-color-orange;


  svg {
    fill: currentColor;
    width: 2rem;
    margin-top: -.125rem;
    height: 1.25rem;
  }
}

.#{$m-css-prefix}-News-text {
  color: $aa-color-gray-700;
  text-decoration: none;
  position: relative;;
  width: 100%;
  padding-right: 2rem;

  p {
    margin: 0;
  }

  svg {
    fill: currentColor;
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 0;
    top: .125rem;
    // transform: translateY(-50%);
  }
}

.#{$m-css-prefix}-NewsArticle-info hr {

}

.#{$m-css-prefix}-News-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

}

.#{$m-css-prefix}-News-tag {
  @extend .#{$m-css-prefix}-EventList-tag;
  margin-bottom: m-toRem($m-f8-8);

  &:last-child {
    margin-right: 0;
  }
}


/********************* News Detail Seite ************************/

.#{$m-css-prefix}-NewsArticle-teaser {
  font-weight: 600;
  margin: 1rem 0;
}

.#{$m-css-prefix}-NewsArticle-header {
  border-bottom: solid 1px $aa-color-gray-300;
  margin-bottom: 1rem;
}

.#{$m-css-prefix}-NewsArticle-print {
  padding: .56rem 0;
}

@include media-breakpoint-down(xs) {
  .ce-left .ce-gallery,
  .ce-column,
  .ce-center .ce-outer,
  .ce-center .ce-inner,
  .ce-right .ce-gallery {
    float: none;
  }
  .ce-gallery img {
    max-width: 100% !important;
  }
}

.#{$m-css-prefix}-btn-bottom {
  position: absolute;
  bottom: 0;
  margin-top: 1rem;
}

.#{$m-css-prefix}-Row--newsList {
  .#{$m-css-prefix}-Card.articletype-0 {
    margin: 2rem 0;
  }
  .card-text p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.#{$m-css-prefix}-NewsArticle-marginal {
  margin-bottom: 1rem;

  &:first-child {
    margin-top: 1rem;
  }

  .news-related-news-date, .news-related-files-size {
    padding-left: 1rem;
  }
}

.#{$m-css-prefix}-NewsArticle-body {
  .frame-type-html {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.#{$m-css-prefix}-NewsArticle-info {

  .#{$m-css-prefix}-NewsArticle-date,
  .news-list-author,
  .news-list-authors-mail,
  .news-list-relatedNews,
  .news-related-files-link {

    position: relative;
    padding-left: 1.5rem;
    display: block;

    .#{$m-css-prefix}-Icon {
      width: 1.25rem;
      height: 1.25rem;
      padding-right: .5rem;
      position: absolute;
      left: 0;


      &.#{$m-css-prefix}-Icon--calendar {
        top: -.125rem;
      }

      &.#{$m-css-prefix}-Icon--profil {
        top: .125rem;
      }

      &.#{$m-css-prefix}-Icon--envelope {
        top: .25rem;
      }
    }
  }

}


// Protected NewsArticle Stylings
//----------------------------------------------------------------------------------------------------------------------


.#{$m-css-prefix}-NewsArticle.#{$m-css-prefix}-NewsArticle--protected {
  .#{$m-css-prefix}-NewsArticle-protection-iframe-wrap {
    display: none;
  }
}

.#{$m-css-prefix}-NewsArticle.#{$m-css-prefix}-NewsArticle--protected.is-protected {

  .#{$m-css-prefix}-NewsArticle-body {
    position: relative;
    overflow: hidden;
  }

  .#{$m-css-prefix}-NewsArticle-protection-iframe-wrap {
    display: block;
    padding-top: 25rem;
    min-height: 100vh;
    z-index: 1;
    background: linear-gradient(to bottom, transparent 10rem, white 20rem);

    &.content-loaded {
      .aa-Content {
        border: 2px solid $aa-color-gray-300;
      }
    }
  }

  .#{$m-css-prefix}-NewsArticle-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
}
