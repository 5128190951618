
.#{$m-css-prefix}-List {

	li {
		padding-left: $m-f8-4;

		&:not(:first-child) {
			margin-top: m-toRem($m-f8-8);
		}
	}
}


// Modifiers
// ------------------------------------------------------------------

.#{$m-css-prefix}-List.#{$m-css-prefix}-List--unordered {
	padding-left: $m-f8-16;
}

.#{$m-css-prefix}-List.#{$m-css-prefix}-List--ordered {
	padding-left: $m-f8-16;
}
