@use 'sass:math';

.#{$m-css-prefix}-Row {}

.#{$m-css-prefix}-Row-wrapper {

	+ .#{$m-css-prefix}-Row-wrapper {

		@each $breakpoint in map-keys($vertical-grid-gutter-widths) {
			@include media-breakpoint-up($breakpoint) {
				$gutter: map-get($vertical-grid-gutter-widths, $breakpoint);
				margin-top: (math.div($gutter, 2));
			}
		}
	}
}


// Modifiers
// -----------------------------------------------------------------------------
