.#{$m-css-prefix}-newsteaser {
  &-head {
    margin-bottom: 32px;
  }

  &-item {
    display: flex;
    flex-direction: column;

    &-inner {
      box-shadow: 0 0 14px #e9ecf4;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    @include media-breakpoint-down(md) {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    &-image {
      max-width: 100%;
      object-fit: cover;
      aspect-ratio: 4/3;
    }
    &-content {
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-headline,
      &-text {
        a {
          color: inherit;
          text-decoration: none;
        }
      }
      &-date {
        font-size: 16px;
        color: #0d1876;

      }

    }
  }
}
