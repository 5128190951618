.#{$m-css-prefix}-Patient {
}

.#{$m-css-prefix}-Patient .#{$m-css-prefix}-Patient-inner {
  padding: m-toRem($m-f8-8) 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(xs) {
    background-color: transparent;
    padding: 0;

    .#{$m-css-prefix}-btn{
      width: 100%;
      border-color: $aa-color-blue;
    }
  }
}


.#{$m-css-prefix}-Patient-title {
  margin: 0;
  font-weight: 400;

  @include m-makeH(4);
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
