
.sb-Image {}

// Modifiers
// -----------------------------------------------------------------------------
.sb-Image.sb-Image--fullWidth {
	width: 100%;
	height: auto;
}

.aa-Section{
  &.round-image{
    .image{
      img{
        border-radius: 50%;
      }
    }
  }
}
