.#{$m-css-prefix}-Icon {

  .#{$m-css-prefix}-Card & {
    width: 3rem;
    height: 3rem;
  }
}


#{$m-css-prefix}-Icon--svg {
  position: absolute;
  right: 0;
  width: m-toRem($m-f8-16);
  height: m-toRem($m-f8-16);
  top: 50%;
  transform: translateY(-50%);
  fill: $aa-color-gray-900;
  transition: $transition-base;
}

// Left is right rotate 180 deg
.#{$m-css-prefix}-Icon--svg-long-arrow-left {
  transform: rotate(180deg);
}


.#{$m-css-prefix}-u-bg--blue.#{$m-css-prefix}-Card {
  .#{$m-css-prefix}-Icon {
    color: white;
  }
}


.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--svg-check {
  color: #407AB6 !important;
}

.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--caret {}

.#{$m-css-prefix}-Icon {
  position: relative;

  >.aa-btn {
    padding-left: 40px;
  }

  >* {
    padding-left: 2rem;

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      display: block;
      z-index: 1000;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }


}

.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--lock {

  width: .8rem;
  height: .8rem;
  padding-right: .25rem;
  margin-top: -3px;
}

.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--socialmedia {

  width: 1.8rem;
  height: 1.8rem;
}

.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--envelope,
.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--calendar,
.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--profil,
.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--print {

  width: 1.25rem;
  height: 1.25rem;
  padding-right: .5rem;
}

.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--mail {
  &:hover {

    .aa-btn--blue,
    .aa-btn--yellow,
    .aa-btn--primary {
      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyMSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4Ljc5MyAzLjk5ODk2TDEwLjc5MyA4Ljk5ODk2TDIuNzkyOTcgMy45OTg5NlYxLjk5ODk2TDEwLjc5MyA2Ljk5ODk2TDE4Ljc5MyAxLjk5ODk2VjMuOTk4OTZaTTE4Ljc5MyAtMC4wMDEwMzc2SDIuNzkyOTdDMS42ODI5NyAtMC4wMDEwMzc2IDAuNzkyOTY5IDAuODg4OTYyIDAuNzkyOTY5IDEuOTk4OTZWMTMuOTk5QzAuNzkyOTY5IDE0LjUyOTQgMS4wMDM2OCAxNS4wMzgxIDEuMzc4NzYgMTUuNDEzMkMxLjc1MzgzIDE1Ljc4ODIgMi4yNjI1NCAxNS45OTkgMi43OTI5NyAxNS45OTlIMTguNzkzQzE5LjMyMzQgMTUuOTk5IDE5LjgzMjEgMTUuNzg4MiAyMC4yMDcyIDE1LjQxMzJDMjAuNTgyMyAxNS4wMzgxIDIwLjc5MyAxNC41Mjk0IDIwLjc5MyAxMy45OTlWMS45OTg5NkMyMC43OTMgMC44ODg5NjIgMTkuODkzIC0wLjAwMTAzNzYgMTguNzkzIC0wLjAwMTAzNzZaIiBmaWxsPSIjMkE0ODkxIi8+Cjwvc3ZnPgo=");
      }
    }
  }

  &>* {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'%3E%3Cpath d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z'/%3E%3C/svg%3E");
    }
  }

  a.aa-btn-outline--blue,
  a.aa-btn-outline-white,
  a.aa-btn-outline--lightBlue,
  a.aa-btn-outline--secondary {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%232A4891'%3E%3Cpath d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z'/%3E%3C/svg%3E");
    }
  }

  &:hover {

    a.aa-btn-outline--blue,
    a.aa-btn-outline-white,
    a.aa-btn-outline--lightBlue,
    a.aa-btn-outline--secondary {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23091A42'%3E%3Cpath d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z'/%3E%3C/svg%3E");
      }
    }
  }
}

.#{$m-css-prefix}-Icon.#{$m-css-prefix}-Icon--phone {
  margin-right: m-toRem($m-f8-8);

  &:hover {

    .aa-btn--blue,
    .aa-btn--yellow,
    .aa-btn--primary {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%232A4891' d='M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z'/%3E%3C/svg%3E");
      }
    }
  }

  &>* {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z'/%3E%3C/svg%3E");
    }
  }
}

// Icons für Links mit Pfeil
//-----------------------------------------------------------------------------------------------------------------------------

a.#{$m-css-prefix}-Icon--arrow-blue:before {
  background-image: url($aa-icon-arrow-blue);
}

a.#{$m-css-prefix}-Icon--arrow-grey:before {
  background-image: url($aa-icon-arrow-grey);
}

a.#{$m-css-prefix}-Icon--arrow-blue,
a.#{$m-css-prefix}-Icon--arrow-grey {
  color: #004e9e;
  display: inline-block;
  text-decoration: none;
  position: relative;
  padding-left: m-toRem($m-f8-24);

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    margin-right: m-toRem($m-f8-4);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 m-toRem($m-f8-4);
  }

  &:hover {
    text-decoration: underline;
  }
}
