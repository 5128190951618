.#{$m-css-prefix}-Info {
  background-color: $aa-color-yellow;
  background: $aa-color-yellow-gradient;
  position: relative;
  color: white;
  padding: 1rem 0;
  border: m-toRem($m-f8-8) solid white;
  overflow: hidden;

  .aa-btn--blue{
    @include hover-focus-active{
      background-color: darken($aa-color-blue, 1);
      color: white;
    }
  }
}

.#{$m-css-prefix}-Info-label {
  @include media-breakpoint-up(xl) {
    font-size: 1.2rem;
  }
}

.#{$m-css-prefix}-Info.#{$m-css-prefix}-Info--desktop {

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .#{$m-css-prefix}-Info-btn-wrap {
    margin-left: 1rem;
  }
  @include media-breakpoint-down(md){
    display:none;
  }
}

body.show-banner,
.show-banner .#{$m-css-prefix}-Info.#{$m-css-prefix}-Info--desktop {

}

.#{$m-css-prefix}-Info.#{$m-css-prefix}-Info--mobile {

  @include media-breakpoint-up(lg){
    display:none;
  }

  @include media-breakpoint-down(sm){
    flex-direction: column;

    .#{$m-css-prefix}-Info-label {
      margin-bottom: 1rem;
    }
  }
}
