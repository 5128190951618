.#{$m-css-prefix}-Survey {
}

.#{$m-css-prefix}-Survey-chart {
  position:relative;
  display: flex;
  justify-content: center;
}
.#{$m-css-prefix}-Survey-pie {
  max-width: 560px;

}
.#{$m-css-prefix}-Survey-questions {
  margin-bottom: m-toRem($m-f8-16)
}

.#{$m-css-prefix}-Survey-legend{

  ul{
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-wrap:wrap;
    flex-direction: column;

    @include media-breakpoint-up(md){
      flex-direction: row;
    }
  }

  li{
    position: relative;
    padding-left: 3rem;
    margin-bottom: m-toRem($m-f8-8);

    flex: 1 0 100%;
    @include media-breakpoint-up(md){
      min-width: 360px;
      flex: 1 0 auto;
    }
  }
}

.#{$m-css-prefix}-Survey-legend-color{
  position: absolute;
  top: 0;
  left: 0;
  height: m-toRem($m-f8-24);
  padding-left: m-toRem($m-f8-40);
}
