.#{$m-css-prefix}-jubileeSticky {
  position: fixed;
  right: 0;
  bottom: 70px;
  z-index: 150;

  &-toggle {
    box-sizing: border-box;
    position: relative;
    width: 70px;
    height: 70px;
    background-color: #2A4891;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 0;
    top: 0;
    transition: 0.75s ease;
  }

  &-wrapper {

    &.is-active {
      .#{$m-css-prefix}-jubileeSticky-content {
        transform: translateX(-280px);
      }

      .#{$m-css-prefix}-jubileeSticky-toggle {
        transform: translateX(-280px);
        animation-name: slide;
        animation-duration: 0.5s;
        animation-delay: 0.85s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
    }

    svg {
      fill: #fff;
      width: 65px;
      height: 65px;
    }
  }

  &-content {
    background-color: #efefef;
    transition: 0.75s ease;
    color: #000a5a;
    transform: translateX(70px);
    width: 350px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;

    @include media-breakpoint-up(lg) {
      transition: 0.75s ease;
    }
  }

}
