// Headlines
// -----------------------------------------------------------------------------
@mixin m-makeH($size: 1) {
	margin-bottom: $headings-margin-bottom;
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;

	@if $size == 1 {
		font-size: $h1-font-size;
    line-height: 68px;

		@include media-breakpoint-down(md) {
			font-size: $h1-font-size-mobile;
      line-height: 38px;
		}

  } @else if $size == 2 {
		font-size: $h2-font-size;
    line-height: 56px;

		@include media-breakpoint-down(md) {
			font-size: $h2-font-size-mobile;
      line-height: 34px;
		}

	} @else if $size == 3 {
		font-size: $h3-font-size;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: $h3-font-size-mobile;
      line-height: 30px;
    }

	} @else if $size == 4 {
		font-size: $h4-font-size;
		line-height: 30px;
		margin-bottom: m-toRem($m-f8-16);

    @include media-breakpoint-down(md) {
      font-size: $h4-font-size-mobile;
      line-height: 28px;
    }

	} @else if $size == 5 {
		font-size: $h5-font-size;
		line-height: 22px;
		margin-bottom: m-toRem($m-f8-16);

    @include media-breakpoint-down(md) {
      font-size: $h4-font-size-mobile;
      line-height: 22px;
    }

	} @else {
		font-size: $h6-font-size;
		line-height: 22px;
		margin-bottom: m-toRem($m-f8-16);

    @include media-breakpoint-down(md) {
      font-size: $h6-font-size-mobile;
      line-height: 22px;
    }
	}
}

/// Viewport sized typography with minimum and maximum values
// -----------------------------------------------------------------------------
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include m-responsive-font(5vw, 35px, 150px, 50px);
///
@mixin m-responsive-font($responsive, $min, $max: false, $fallback: false) {
	$responsive-unitless: $responsive / ($responsive - $responsive + 1);
	$dimension: if(unit($responsive) == 'vh', 'height', 'width');
	$min-breakpoint: $min / $responsive-unitless * 100;

	@media (max-#{$dimension}: #{$min-breakpoint}) {
		font-size: $min;
	}

	@if $max {
		$max-breakpoint: $max / $responsive-unitless * 100;

		@media (min-#{$dimension}: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	@if $fallback {
		font-size: $fallback;
	}

	font-size: $responsive;
}

// Ornament
// -----------------------------------------------------------------------------

// Add an arrow
@mixin m-addNavArrow($color: $m-mediatis-body) {

	@if $color == $body-color {
		background-image: url($m-icon-angle-right-svg);

	} @else if $color == white {
		background-image: url($m-icon-angle-right-white-svg);
	}

	background-position: right 0.2em bottom 0.41em;
	background-repeat: no-repeat;
	background-size: 0.33em;
	padding-right: 0.8em;
	text-decoration: none;
	transition: background-position 0.2s ease;

	@include hover {
		background-position: right bottom 0.41em;
	}
}
