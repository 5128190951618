
.#{$m-css-prefix}-Row--2rows3cols {
  .col {
    display: inline-flex;
  }
}

.#{$m-css-prefix}-Section.#{$m-css-prefix}-Section--featureGrid {
  font-size: 16px;
  @include media-breakpoint-up(md) {
    padding: 20px 0;
  }

  h2{
    font-size: 40px;
    line-height: 48px;

    @include media-breakpoint-down(sm) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  .#{$m-css-prefix}-intro-text{
    font-size: 18px;
    line-height: 30px;
  }
  .#{$m-css-prefix}-Card.no-arrow header .#{$m-css-prefix}-Title:after {
    content: none;
  }
  .#{$m-css-prefix}-border-white {
    @include media-breakpoint-down(sm) {
      border-top: 2px solid rgba(255,255,255,.2);
      margin-left: -30px;
      margin-right: -30px;
    }
  }
}

.#{$m-css-prefix}-Section.#{$m-css-prefix}-Section--featureGrid .#{$m-css-prefix}-Card {

  .#{$m-css-prefix}-Title {
    text-transform: none;
    margin: 0;
  }
  .#{$m-css-prefix}-Icon > * {
    padding: 0;
  }
  @include media-breakpoint-down(sm) {

    margin-bottom: 0;
    margin-top: m-toRem($m-f8-4);
    position: relative;

    &:after {
      content: '';
      border-bottom: 2px solid rgba(255,255,255,.2);
      position: absolute;
      bottom: m-toRem($m-f8-4);
      left: -15px;
      right: -15px;
      z-index: $zIndex-2;
    }

    .#{$m-css-prefix}-Card-icon-wrapper {
      position: absolute;
      top: 18px;
      .#{$m-css-prefix}-Icon {
        width: 32px;
        height: 32px;
        &>*{
          width: inherit;
          height: inherit;
        }
      }
      & + .#{$m-css-prefix}-Card-title {
        padding-left: m-toRem($m-f8-64);
      }
    }

    .#{$m-css-prefix}-Card-title {
      padding: m-toRem($m-f8-16) 0 m-toRem($m-f8-16);
      transition: $transition-base;
      .#{$m-css-prefix}-Title {
        font-size: 20px;
        font-weight: 600;
      }
    }

    header {
      *:not(.#{$m-css-prefix}-Title) {
        display: none;
      }

      .#{$m-css-prefix}-Title {
        position: relative;
        margin-top: 0;
        padding-right: 1rem;
        color: #ffffff;

        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E");
          position: absolute;
          width: 1rem;
          height: 1rem;
          top: calc(50% - .75rem);
          right: 0;
          transition: $transition-base;

        }
      }
    }


    .#{$m-css-prefix}-Card-body {
      width: 100%;
      padding: 0 m-toRem($m-f8-16);
      color: #ffffff;
      max-height: 1px;
      overflow: hidden;
      z-index: $zIndex-2;
    }

    &.is-active {
      .#{$m-css-prefix}-Card-body {
        padding: m-toRem($m-f8-16);
        max-height: 200em;
        transition: all .35s ease-in-out;
      }

      .#{$m-css-prefix}-Card-title {
        border-color: transparent;


        .#{$m-css-prefix}-Title {
          &:after {
            top: calc(50% - .25rem);
            transform: scaleY(-1);
          }
        }
      }

    }
  }

}

// Modifier
//----------------------------------------------------------------------------------------------------------------------

// .#{$m-css-prefix}-Section.#{$m-css-prefix}-Section--featureGrid.#{$m-css-prefix}-u-bg--blue:after {
//   content: '';
//   border-bottom: 1rem solid rgba(0, 0, 0, 0.2);
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// }




