.#{$m-css-prefix}-tabs {
  border-bottom: 2px solid rgba(9, 26, 66, .15);
  display: flex;

  &-container {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: -30px;
      top: 0;
      display: block;
      height: 70px;
      width: 100px;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 109.21%);
    }
  }

  @include media-breakpoint-down(md) {
    overflow-x: auto;
    overflow-y: clip;
  }

  span {
    white-space: nowrap;
  }

  &-header {
    color: #000a5a;
  }

  &-tab {
    color: #000a5a;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    padding: 14px 23px;
    cursor: pointer;

    &:first-child {
      padding-left: 0;

      &.active::before {
        left: 0;
        width: calc(100% - 23px);
      }
    }

    &.active {
      position: relative;

      &::before {
        content: '';
        background: linear-gradient(213.42deg, #2A4891 10.25%, #032578 72.61%);
        border-radius: 8px;
        position: absolute;
        left: 24px;
        top: 50px;
        right: 15px;
        width: calc(100% - 46px);
        height: 6px;
      }
    }
  }
}

.#{$m-css-prefix}-tab {
  &-content {
    >.column-1 {
      padding: 0;
      width: auto;

      >div[id] {
        .ce-textpic {
          @include media-breakpoint-up(lg) {
            display: flex;

            .ce-gallery {
              width: 50%;
              margin-right: 32px;
            }

            .ce-bodytext {
              width: 50%;
            }
          }
        }

      }
    }
  }
}
