.#{$m-css-prefix}-Slider {
  position: relative;
  width: 100%;

  @include media-breakpoint-up(xl) {
    background-color: transparent;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-slide {

    @include media-breakpoint-down(md) {
      background-color: #fff;
    }
  }

  .slick-track {
    padding-bottom: 100px;
  }

  &-grid {

    .slick-dots {
      bottom: -50px;
      text-align: right;
      li {
        width: 63px;

        button {
          width: 100%;
          padding: 0;
          height: 0;
          border: 2px solid rgba(9,26,66,.15);
          border-radius: 6px;
          &::before {
            content: unset;
          }
        }
        &:not(.slick-active):hover button,
        &.slick-active button {
          border: 2px solid #234796;
        }
      }
    }

    .slick-next,
    .slick-prev {
      height: 50px;
      width: 26px;
      &::before {
        font-size: 50px;
        color: transparent;
      }
    }
    .slick-next {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='50' viewBox='0 0 26 50' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7263 25.4742L1.28474 7.63884C0.748773 6.80336 0.991576 5.69157 1.82706 5.1556L7.12178 1.75899C7.95727 1.22302 9.06905 1.46582 9.60502 2.3013L24.4707 25.4742L9.60502 48.6472C9.06905 49.4827 7.95727 49.7255 7.12178 49.1895L1.82706 45.7929C0.991577 45.2569 0.748773 44.1451 1.28474 43.3096L12.7263 25.4742Z' stroke='%23ADC2DD'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
      }
    }
    .slick-prev {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='50' viewBox='0 0 26 50' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.882 25.4742L24.3235 43.3096C24.8595 44.1451 24.6167 45.2569 23.7812 45.7929L18.4865 49.1895C17.651 49.7255 16.5392 49.4827 16.0032 48.6472L1.13758 25.4742L16.0033 2.3013C16.5392 1.46582 17.651 1.22302 18.4865 1.75899L23.7812 5.1556C24.6167 5.69157 24.8595 6.80336 24.3235 7.63884L12.882 25.4742Z' stroke='%23ADC2DD'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
      }
    }
  }
}

.#{$m-css-prefix}-Slide {
  &-subheader {
    font-size: 15px;
    line-height: 24px;
    color: #234796;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    letter-spacing: 1.5px;
  }

  &-image {}

  &-video {
    .#{$m-css-prefix}-Slide-video-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    iframe,
    video {
      width: 100%;
    }
  }

  &-header {
    font-size: 32px;
    line-height: 38px;
    color: #000a5a;
    font-weight: 600;

    @include media-breakpoint-up(xl) {
      font-size: 48px;
      line-height: 58px;
    }
  }

  &-image-wrapper {
    @include media-breakpoint-up(xl) {
      width: 50%;
      float: left;
      margin-left: 35%;
    }

    @include media-breakpoint-down(lg) {
      order: 0;
    }
  }

  &-caption {
    @include media-breakpoint-up(xl) {
      top: 0;
      left: 0;
      width: 50%;
      z-index: 100;
      float: left;
    }

    @include media-breakpoint-down(lg) {
      order: 1;
    }
  }

  &-grid {
    box-shadow: 0px 0px 14px #e9ecf4;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 120px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 10px 20px;
    @include media-breakpoint-down(sm) {
      max-height: 70px;
      padding: 12px;
    }
    .#{$m-css-prefix}-Slider-grid-link{
      max-height: inherit;
    }
    img {
      height: 100px;
      width: 100%;
      object-fit: contain;
      max-height: inherit;

      @include media-breakpoint-down(sm) {
        height: 47px;
      }
    }
  }
}

.#{$m-css-prefix}-Slide {
  &-white {
    .#{$m-css-prefix}-Slide {
      &-header {
        color: $white;
      }
      &-subheader {
        color: $white;
      }
    }

    .#{$m-css-prefix}-Slide-caption-body {
      color: $white;
    }
  }
}

.#{$m-css-prefix}-Slide {
  &-inside {
    .#{$m-css-prefix}-Slide {
      &-image-wrapper {
        @include media-breakpoint-up(xl) {
          width: 100%;
          float: unset;
          margin-left: unset;
        }
      }
    }
    .#{$m-css-prefix}-Slide-wrap {
      &-wide {
        @include media-breakpoint-up(xl) {

          .#{$m-css-prefix}-Slide-video,
          .#{$m-css-prefix}-Slide-image {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }

    .#{$m-css-prefix}-Slide-caption {
      @include media-breakpoint-up(xl) {
        left: 25px;
      }
    }
  }
}

.#{$m-css-prefix}-Slide {
  height: 100%;

  @include media-breakpoint-down(lg) {
    .container {
      padding: 0;
    }

    &-caption {
      margin-top: 24px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.#{$m-css-prefix}-Slide:not(:first-child) {
  display: none;
}

.#{$m-css-prefix}-Slide-wrap {
  position: relative;

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(xl) {
    width: 100%;
    justify-content: flex-end;
  }

  &-wide {
    @include media-breakpoint-up(xl) {

      .#{$m-css-prefix}-Slide-video,
      .#{$m-css-prefix}-Slide-image {
        width: 65vw;
        max-width: 960px;
      }
    }
  }

  .#{$m-css-prefix}-Title.#{$m-css-prefix}-Title--withMobileUnderline {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.#{$m-css-prefix}-Slider-slide-image {
  width: 100%;
}

.slick-track,
.slick-list {
  &.active {
    -webkit-transform: none !important;
    transform: none !important;
  }
}

.#{$m-css-prefix}-Slide-caption {

  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    &.active {
      position: static;
    }

    &.hide {
      display: none;
    }

  }
}

.#{$m-css-prefix}-Slide-caption-body {
  @include media-breakpoint-down(lg) {

    a,
    p a {
      &:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.#{$m-css-prefix}-Slide-caption-body,
.#{$m-css-prefix}-Slide-caption-title {
  padding: 0;
}

.#{$m-css-prefix}-Slider-arrows {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 100;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.#{$m-css-prefix}-Slider-arrow {
  position: absolute;
  cursor: pointer;
  display: none;
  transition: $transition-base;
  padding: m-toRem($m-f8-8);

  @include media-breakpoint-up(md) {
    padding: m-toRem($m-f8-4*3);
  }

  svg {
    transition: $transition-base;
    fill: white;
    stroke: $aa-color-rgba-blue;
    width: 18px;
    height: 40px;

    @include media-breakpoint-up(md) {
      width: 18px;
      height: 40px;
    }
  }

  &:hover {
    svg {
      fill: #234796;
      stroke: #234796;
      filter: drop-shadow(0 0 20px $aa-color-rgba-blue);
    }
  }

  &--next {
    right: 0;
    transform: translateY(-175%);

    @include media-breakpoint-up(xl) {
      transform: translateY(-50%);
    }
  }

  &--prev {
    left: 0;
    transform: translateY(-175%) rotate(180deg);

    @include media-breakpoint-up(xl) {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.#{$m-css-prefix}-Slider-nav-dots {
  position: relative;

  .slick-dots {
    bottom: m-toRem($m-f8-8);

    @include media-breakpoint-up(xl) {
      text-align: right;
    }

    li {
      width: 75px;

      button {
        width: 100%;
        padding: 0;
        height: 0;
        border-bottom: 4px solid rgba(9, 26, 66, 0.15);
        border-radius: 6px;

        &:before {
          content: none;
        }
      }

      &:not(.slick-active) {
        button:hover {
          border-bottom: 4px solid #000a5a;
        }
      }

      &.slick-active button {
        border-bottom: 4px solid #234796;
      }
    }
  }
}

// Header Slider Modifiaktions
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Slider.#{$m-css-prefix}-Slider--white {

  .#{$m-css-prefix}-Slide-caption {
    @include media-breakpoint-up(xl) {

      p,
      a:not(.btn),
      h1,
      h2,
      h3,
      h4 {
        color: white;
      }
    }
  }
}

// Header-Headlines immer gleich
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Slide-caption-body {
  font-size:18px;
  line-height:28px;
  color: #000a5a;
  font-weight: 400;

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: $h3-font-size;
    line-height: 120%;
    margin-bottom: 1rem;

    @include media-breakpoint-up(xl) {
      font-size: $h2-font-size;
    }

    @include media-breakpoint-down(sm) {
      font-size: $h4-font-size;
      margin-top: m-toRem($m-f8-16);
    }
  }
}

// in context
//----------------------------------------------------------------------------------------------------------------------

// #aa-id-4 .#{$m-css-prefix}-Slide-caption {

//   @include media-breakpoint-up(xl) {
//     // padding-bottom: m-toRem($m-f8-104);
//   }

//   @include media-breakpoint-up(xxl) {
//     // padding-bottom: m-toRem($m-f8-64*2);
//   }

//   @include media-breakpoint-down(xl) {
//     // padding-bottom: 0;
//   }
// }
