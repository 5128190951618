/************* Cookiebot Button Styling  ******************/

// #CybotCookiebotDialogBodyUnderlay {
//   opacity: 0.6 !important;
// }

#CybotCookiebotDialog {
  &,
  #CybotCookiebotDialogBodyContentControls,
  #CybotCookiebotDialogBodyContentTitle,
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
  #CybotCookiebotDialogBodyButtonDecline,
  #CybotCookiebotDialogBodyContentText,
  input[type="checkbox"].CybotCookiebotDialogBodyLevelButton + label,
  a,
  div {
    font-family: "Helvetica Neue LT", sans-serif !important;
  }

  // Changes for Swift Design

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
  #CybotCookiebotDialogBodyButtonDecline {
    font-weight: 600;
    font-size: 16px;
    border-radius: 40px;
    width: auto !important;
    min-width: 33%;
    padding: 14px 18px;
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
  #CybotCookiebotDialogBodyButtonDecline {
    &:hover {
      color: #091A42;
      background-color: transparent;
      border: 2px solid #091A42
    }

    &:focus,
    &:active {
      color: #091A42;
      background-color: transparent;
      border: 2px solid #091A42;
      box-shadow: 0px 0px 6px rgba(9, 26, 66, 0.25);
    }
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    border: 2px solid transparent;
    background: linear-gradient(269.41deg, #2a4891 0.51%, #032578 99.39%);

    &:hover {
      background: transparent;
      border: 2px solid #2A4891;
      color: #2A4891;
    }

    &:active,
    &:focus {
      background: transparent;
      border: 2px solid #2A4891;
      color: #2A4891;
      box-shadow: 0px 0px 6px rgba(9, 26, 66, 0.25);
    }
  }
}
