.#{$m-css-prefix}-popup {
  margin: -36px -36px -36px -36px;
  color: #fff;

  &-content {
    position: relative;
    padding: 80px 30px;

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      min-height: 350px;
    }

    &-outer {
      max-width: 95%;
      min-height: 75%;
      background: linear-gradient(316.74deg, #234796 19.09%, #000a5a 72.24%) !important;
      display: flex;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        max-width: 70%;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-up(xl) {
        width: 50%;
      }
    }

    &-image {
      width: 50%;
      margin-right: 30px;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        // height: 100%;
      }
    }
  }

  &-inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 18px;
  }
}
