.#{$m-css-prefix}-pagination {
  margin: 30px 0 50px;

  ul.f3-widget-paginator {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;
    align-items: center;

    li {
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: #234796;
      margin: 0 8px;

      a {
        text-decoration: none;
      }

      &.current {
        position: relative;
        color: #000a5a;

        &::after {
          content: '';
          width: 100%;
          height: 3px;
          background-color: #000a5a;
          position: absolute;
          bottom: -5px;
          left: 0;
        }
      }
    }

  }
}
