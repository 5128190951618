
.m-BreakpointDetector {}

.m-BreakpointDetector-value {
	display: block;

	&:before {

		@each $breakpoint in map-keys($grid-breakpoints) {
			@include media-breakpoint-up($breakpoint) {
				content: quote( m-strip-unit( #{map-get($grid-breakpoints, $breakpoint)}) );
			}
		}
	}
}
