.survey {
  &-trigger {
    &-btn {
      appearance: none;
      background: none;
      box-shadow: none;
      border: none;
      position: relative;
      display: flex;

      &.aa-btn--blue {

        span.loader {
          display: none;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          margin-left: 10px;
          background-color: transparent;
          border: 2px solid transparent;
          border-top: 2px solid #032578;
          border-left: 2px solid #032578;
          animation: 1s spin linear infinite;

          @keyframes spin {
            from {
              transform: rotate(0deg);
            }

            to {
              transform: rotate(360deg);
            }
          }
        }

        &.loading {
          opacity: 0.6;

          span.loader {
            display: block;
          }
        }
      }
    }
  }

  &-close {
    &-btn {
      position: fixed;
      right: 0;
      top: 0;
      z-index: 3000000000;
      width: 40px;
      height: 40px;
      opacity: 0;
      overflow: hidden;
      background-color: black;
      text-indent: -5000px;
      border: 0;

      &:not(.show) {
        display: none;
      }
    }
  }
}

#ss_survey_widget {
  >.ss-survey-launch-button {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }
}
