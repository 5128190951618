
.#{$m-css-prefix}-Special.#{$m-css-prefix}-Special--zytotax {
  background: rgb(48, 78, 154) linear-gradient(35deg, rgb(48, 78, 154), rgb(19, 33, 64));

  .#{$m-css-prefix}-Slide-caption-title {
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    picture {
      display: none;
    }
  }

  .#{$m-css-prefix}-Slide-caption {
    background-color: transparent;
    padding: 0;

    @include media-breakpoint-up(sm) {
      .container-fluid {
        margin: m-toRem($m-f8-40) m-toRem($m-f8-40) 0;
      }
    }

    @include media-breakpoint-up(lg) {
      position: absolute;

      .container-fluid {
        margin: m-toRem($m-f8-40) 0 0;
        height: 100%;

        .row {
          height: 100%;
        }
      }
    }
  }

  .#{$m-css-prefix}-Slide-caption-title {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }

  .#{$m-css-prefix}-Special-svg {

    width: 100%;
    height: auto;
    z-index: 1;

    @include media-breakpoint-up(md) {
      width: auto;
      height: 100%;
    }

    &.#{$m-css-prefix}-Special-svg--bg{
      position:absolute;
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
      z-index:-2;
    }
    &.#{$m-css-prefix}-Special-svg--data-line{
      position:absolute;
      filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, .7));
      z-index: -1;

      .t3m-Special-svg-data{
        stroke: #fff;
        stroke-dasharray: 15, 15;
        animation: dashOne .4s linear infinite;
      }

    }

    .#{$m-css-prefix}-Special-dot {
      cursor: pointer;
      animation: pulse 1s ease-out infinite;

      animation-iteration-count: infinite;
      transition: color $duration-normal ease-in-out;
    }

    #marker-one {
      fill: #ebc466;
      animation-delay: 1.3s;
      animation-duration: 2.3s;

      &:hover {
        fill: darken(#ebc466, 10%);
      }
    }

    #marker-two {
      fill: #f2f5fa;
      animation-delay: 1s;
      animation-duration: 2s;

      &:hover {
        fill: darken(#f2f5fa, 10%);
      }

    }
  }
}


@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes dashOne {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 30;
  }
}
