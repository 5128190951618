
.#{$m-css-prefix}-Events {
	position: relative;
	width: 100%;

	&.#{$m-css-prefix}-Events--table {

		@each $breakpoint in map-keys($vertical-grid-gutter-widths) {
			@include media-breakpoint-up($breakpoint) {
				$gutter: map-get($vertical-grid-gutter-widths, $breakpoint);
				padding-bottom: $gutter;
			}
		}
	}
}

// Items
.#{$m-css-prefix}-Events-item {

	&:not(:first-child) {
		margin-top: 2px;
	}
}

.#{$m-css-prefix}-Events-item--table {

	&:nth-child(even) {
	}

	&:nth-child(odd) {

		.#{$m-css-prefix}-Events-cell {
			background-color: $aa-color-gray-200;
		}
	}
}

// Links
.#{$m-css-prefix}-Events-linkWrap:visited,
.#{$m-css-prefix}-Events-linkWrap {
	display: flex;
	text-decoration: none;

	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
	}

	&:focus,
	&:active,
	&:hover {
		text-decoration: none;
		color: $aa-color-blue;


		.#{$m-css-prefix}-Events-date,
		.#{$m-css-prefix}-Events-text {
			color: $aa-color-blue;
		}
	}
}

// Cells
.#{$m-css-prefix}-Events-cell {
	flex: 1 0 0px;
	max-width: 100%;
	padding: 0.5rem;

	&:not(:first-child) {
		margin-left: 2px;

		@include media-breakpoint-down(sm) {
			margin-left: 0;
		}
	}

	&.#{$m-css-prefix}-Events-cell--date {
		flex: 0 0 33%;
		max-width: 33%;
		color: $aa-color-orange;

		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 2px;
		}

		svg {
			fill: currentColor;
			width: 2rem;
			margin-top: -.125rem;
			height: 1.25rem;
		}
	}

	&.#{$m-css-prefix}-Events-cell--text {
		color: $aa-color-gray-700;
		text-decoration: none;
		position: relative;

		p {
			margin: 0;
		}
	}

	&.#{$m-css-prefix}-Events-cell--icon {
		flex: 0 0 auto;

		svg {
			fill: currentColor;
			width: 1rem;
			height: 1rem;
		}
	}
}


/* Veranstaltungen Filter bei Apotheken ausblenden / AUAE-64, Kommentar von Cindy vom 25.11.2019 */

#aa-id-32 .#{$m-css-prefix}-Event-filter.#{$m-css-prefix}-Form-group.Form-group {
	display: none;
}
