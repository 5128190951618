@use 'sass:math';

$grid_gutter_half: #{math.div($grid-gutter-width,2)};

.#{$m-css-prefix}-DecisionHub {
  position: relative;
}

.#{$m-css-prefix}-DecisionHub-wrapper {
  @extend .container;

  padding: .75rem 1rem;
  display: flex;
  flex-wrap: wrap;


  .#{$m-css-prefix}-Slider-nav-dots {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include media-breakpoint-down(lg) {
      left: $grid_gutter_half;
      right: $grid_gutter_half;
    }

    .slick-dots {
      bottom: auto;
      top: 0;
      left: 0;
      transform: translateY(-100%);

      @include media-breakpoint-down(md) {
        bottom: auto;
      }
    }
  }
}


.#{$m-css-prefix}-DecisionHub-banner{
  position: relative;
  width: 100%;

  .#{$m-css-prefix}-DecisionHub-banner-link,
  .#{$m-css-prefix}-DecisionHub-banner-link:visited {
    display: flex;
    text-decoration: none;
    background-color: white;
    padding: m-toRem($m-f8-8);
    margin-bottom: .15rem;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }

    &:focus,
    &:active,
    &:hover{
      text-decoration: none;
      color: $aa-color-blue;

      .#{$m-css-prefix}-DecisionHub-banner-categorie,
      .#{$m-css-prefix}-DecisionHub-banner-text{
        color: $aa-color-blue;
      }
    }

  }
}


.#{$m-css-prefix}-DecisionHub-banner-categorie {
  min-width: 12rem;
  color: #DE9D00;
  margin-right: .5rem;


  svg {
    fill: currentColor;
    width: 2rem;
    margin-top: -.125rem;
    height: 1.25rem;
  }
}

.#{$m-css-prefix}-DecisionHub-banner-text {
 @extend .#{$m-css-prefix}-News-text;
}

.#{$m-css-prefix}-DecisionHub--aerzte .#{$m-css-prefix}-DecisionHub-wrapper {

  background-color: white;

  @include media-breakpoint-up(xxl) {
      max-width: 1500px;
  }

  .#{$m-css-prefix}-btn-wrap {
    flex: 1 1 20%;
    padding: m-toRem($m-f8-4);
    max-width: 20%;

    @include media-breakpoint-up(xxl) {
      &:nth-child(5n+1) {
        padding-left: 0;
      }

      &:nth-child(5n) {
        padding-right: 0;
      }
    }

    @include media-breakpoint-down(xl) {
      flex: 1 1 25%;
      max-width: 25%;
      &:nth-child(4n+1) {
        padding-left: 0;
      }

      &:nth-child(4n+2) {
        padding-right: 0;
      }
    }

    @include media-breakpoint-down(md) {
      flex: 1 1 50%;
      max-width: 50%;
      &:nth-child(2n+1) {
        padding-left: 0;
      }

      &:nth-child(2n+2) {
        padding-right: 0;
      }
    }
    @include media-breakpoint-down(xs) {
      max-width: 100%;
      flex: 1 1 100%;
      padding-left: 0;
      padding-right: 0;
    }


    .#{$m-css-prefix}-btn {
      width: 100%;
    }
  }
}

.#{$m-css-prefix}-DecisionHub--apotheker .#{$m-css-prefix}-DecisionHub-wrapper {

  //@include media-breakpoint-up(md) {
  //  transform: translateY(calc(-50% - .5rem));
  //}

  .#{$m-css-prefix}-DecisionHub-btn-wrapper {
    width: 100%;
    display: flex;
    background-color: white;
    padding: .75rem 1rem;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  .#{$m-css-prefix}-btn-wrap {
    flex: 1 1 38%;
    max-width: 38%;
    padding: m-toRem($m-f8-4);

    .#{$m-css-prefix}-btn {
      width: 100%;
    }

    &:nth-child(1) {
      padding-left: 0;
    }

    &:last-child {
      flex: 1 1 24%;
      padding-right: 0;
      max-width: 24%;
    }

    @include media-breakpoint-down(sm) {
      max-width: 33%;
      flex: 1 1 33%;

      &:last-child {
        flex: 1 1 33%;
        max-width: 33%;
      }
    }
    @include media-breakpoint-down(xs) {
      max-width: 100%;
      flex: 1 1 100%;
      padding-left: 0;
      padding-right: 0;

      &:last-child {
        flex: 1 1 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.#{$m-css-prefix}-DecisionHub.#{$m-css-prefix}-DecisionHub--aerzte {
  padding-bottom: m-toRem($m-f8-24 * 2);

  @include media-breakpoint-up(xl) {
    padding-bottom: 7.75rem;

    .#{$m-css-prefix}-DecisionHub-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }
}

.#{$m-css-prefix}-DecisionHub.#{$m-css-prefix}-DecisionHub--apotheker {


  .#{$m-css-prefix}-DecisionHub-wrapper {
    padding: 0 .75rem .75rem;;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 5.5rem;

    .#{$m-css-prefix}-DecisionHub-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0;
    }
  }
}
