.#{$m-css-prefix}-Protected {

  &-wrapper{
    transition: height .2s ease-in-out;
  }

  &-iframe {

    width:100%;
    border: none;
    transition: $transition-base;
    opacity: 0;

    &.content-loaded {
      border: 2px solid $aa-color-gray-300;
      opacity: 1;
    }
  }
}


.#{$m-css-prefix}-Content--empty {
  padding-top: 2rem;
}
