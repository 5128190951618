.#{$m-css-prefix}-Section {
  position: relative;

  // has background image
  &.#{$m-css-prefix}-Section--backgroundImage,
  &[style*="background-image"] {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 50vmin;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm){
      background-image: none !important;
    }
  }
}


// Modifiers
// -----------------------------------------------------------------------------

// Default section
.#{$m-css-prefix}-Section.#{$m-css-prefix}-Section--default {

  > *:not(.#{$m-css-prefix}-Row-wrapper) {

    @each $breakpoint in map-keys($vertical-grid-gutter-widths) {
      @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($vertical-grid-gutter-widths, $breakpoint);
        padding-top: $gutter;
        padding-bottom: $gutter;
      }
    }
  }
}

.#{$m-css-prefix}-Section.#{$m-css-prefix}-Section--default.#{$m-css-prefix}-Section--double {

  > *:not(.#{$m-css-prefix}-Row-wrapper) {

    @each $breakpoint in map-keys($vertical-grid-gutter-widths) {
      @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($vertical-grid-gutter-widths, $breakpoint);
        padding-top: $gutter*2;
        padding-bottom: $gutter*2;
      }
    }
  }
}

.container-fluid .#{$m-css-prefix}-Section.#{$m-css-prefix}-Section--boxed {

  @each $breakpoint in map-keys($vertical-grid-gutter-widths) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($vertical-grid-gutter-widths, $breakpoint);
      margin: $gutter;
    }
  }
}


.#{$m-css-prefix}-Content.#{$m-css-prefix}-Content--switch > .#{$m-css-prefix}-Section:first-child {
  padding-top: m-toRem($m-f8-104);
}

.#{$m-css-prefix}-Content.#{$m-css-prefix}-Content--home > .#{$m-css-prefix}-Section,
.#{$m-css-prefix}-Feature > .#{$m-css-prefix}-Section {
  @include media-breakpoint-up(lg) {
  }
}


