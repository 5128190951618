.contenttable {
  width: 100%;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }

  tr:nth-child(even) {
    background: rgba(0, 60, 122, .05);
  }

  tr:nth-child(odd) {
    background: #ffffff;
  }

  td {
    padding: 1rem;
    vertical-align: top;
    text-align: left;

    @include media-breakpoint-down(sm) {
      hyphens: auto;
    }
  }
}

.table-responsive {
  overflow-x: auto;
}
