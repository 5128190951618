
.frame.frame-type-header {}

.frame.frame-type-text {}

.frame.frame-type-textpic {}

.frame.frame-type-image {}

.frame.frame-type-textmedia {}

.frame.frame-type-bullets {}

.frame.frame-type-table {}

.frame.frame-type-uploads {}


// Text CEs
.frame.frame-type-text,
.frame.frame-type-textpic,
.frame.frame-type-textmedia,
.frame.frame-type-bullets,
.frame.frame-type-table {

	+ .frame.frame-type-text,
	+ .frame.frame-type-textpic,
	+ .frame.frame-type-textmedia,
	+ .frame.frame-type-bullets,
	+ .frame.frame-type-table {
		margin-top: m-toRem($m-f8-64);
	}
}

