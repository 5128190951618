
// Custom Checkbox Stylings
//----------------------------------------------------------------------------------------------------------------------

.#{$m-css-prefix}-Form-group {
  .custom-checkbox {

    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;

    .custom-control-input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked ~ .custom-control-label:before {
        color: white;
        border-color: $aa-color-gray-300;
        background-color: white;
      }

      &.has-error:checked ~ .#{$m-css-prefix}-Form-error-wrapper{
        max-height: 0;
        opacity: 0;
      }

      &:not(:disabled):active ~ .custom-control-label:before {
        color: white;
        background-color: transparent;
      }

      &:focus:not(:checked) ~ .custom-control-label:before {
        border-color: $aa-color-gray-300;
      }

      &:focus ~ .custom-control-label:before {
        box-shadow: none;
      }

      &:checked ~ .custom-control-label:after {
        background-image: none;
        background-color: $aa-color-orange;
      }
      &:not(:disabled):active ~ .custom-control-label:before {
        background-color: $aa-color-rgba-orange-3;
        border-color: $aa-color-orange;
      }
    }


    .custom-control-label {

      p{
        margin-bottom: 0;
      }

      &:before {
        border: 1px solid $aa-color-gray-300;
        top: 0;
        left: -27px;
        display: block;
        width: 22px;
        height: 22px;
      }

      &:after {
        border: 4px solid white;
        top: 1px;
        left: -26px;
        display: block;
        width: 20px;
        height: 20px;
        content: "";
      }
    }
  }
}
