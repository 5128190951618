.#{$m-css-prefix}-Section {
  &-partnergrid {
    .aa-Row {
      .col-6.col-md-6.col-lg-3 {
        >.frame {
          &:not(:last-child) {
            margin-bottom: 27px;
          }
        }

        .ce-image {
          box-shadow: 0px 0px 14px #e9ecf4;
          padding: 20px 20px 0 20px;
        }
      }
    }
  }
}

.#{$m-css-prefix}-Tile-text-wrapper {
  @include media-breakpoint-up(md) {
    padding-right: 40px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}
.#{$m-css-prefix}-Tile-text-right {
  flex-direction: row-reverse;
  .#{$m-css-prefix}-Tile-text-wrapper {
    @include media-breakpoint-up(md) {
      padding-right: 0px;
      padding-left: 40px;
    }
  }
}
